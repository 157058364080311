.form-field {
  position: relative;
}

.form-field + .form-field {
  margin-top: space(sm);
}

.form-field__label {
  @include font-size(paragraph, sm);
}

.main-search-form > .container {
  color: #fff;
  padding: space(sm);
  @include breakpoint(md) {
	padding: space(xl);
  }
}

*:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}



@import 'form-group';
@import 'default';
@import 'select';
@import 'checkbox';
@import 'radio';
@import 'switch';
@import 'slider';
@import "date";
@import "dropdown";
@import "campaignmonitor";