$nav-padding-top: 100px;

header {
  color: palette(white);
  text-transform: uppercase;
  font-weight: font-weight(paragraph, bold);
  @include font-size(paragraph, base);
  text-align: center;
  letter-spacing: 1px;
}

#sidenav {
  @include breakpoint(0, md) {
	.nav-icons {
	  position: absolute;
	  top: 0;
	  width: 100%;
	  padding-top: space(sm);
	  padding-right: space(sm);
	  text-align: right;
	  li.sidenav-toggle-hamb {
		position: absolute;
		padding-left: space(sm);
		left: 0;
	  }
	}
  }

  .nav-icons li {
	display: inline-block;
	//width: 50px;

	&:not(:last-child) {
	  padding-right: 1.3rem;
	}

	transition: all .1s ease-in-out;
	&:hover {
	  transform: scale(1.2, 1.2);
	}

	a:hover {
	  border-bottom: 0 !important;
	  //color: color(gray, light);
	  svg {
		//color: color(gray, light);
	  }
	}
	@include breakpoint(md) {
	  &.sidenav-toggle-hamb {
		display: none;
	  }
	}
	a {
	  position: relative;
	  .cart_count, .wishlist_count {
		position: absolute;
		right: -12px;
		bottom: -3px;
		//margin-right: -16px;
		//margin-left: 0;
		@include font-size(paragraph, sm);
	  }
	  .cart_count_active, .wishlist_count_active {
		//margin-left: -8px;
	  }
	}

  }

  @include breakpoint(md) {
	.nav-icons {
	  padding-top: 5rem;
	}
  }

  .nav-main li {
	line-height: 2rem;
	&.active a {
	  border-bottom: 2px solid #fff;
	}

	a {
	  // Required, since we're setting absolute on pseudo-elements
	  position: relative;
	  vertical-align: middle;

	  &::before,
	  &::after {
		box-sizing: border-box;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
	  }

	  // Set up base styles, we're going to scale instead of animating width/height
	  &::before,
	  &::after {
		top: 2px;
		left: 0;
		height: 100%;
		width: 100%;
		transform-origin: center; // Ensure scaling is done from the center (expands outwards)
	  }

	  // scale3d(<scale-horizontal>, <scale-vertical>, <scale-depth>);
	  &::before {
		//border-top: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: scale3d(0, 1, 1); // Shrink only width
	  }

	  &::after {
		//border-left: 2px solid #fff;
		//border-right: 2px solid #fff;
		//transform: scale3d(1,0,1); // Shrink only height
	  }

	  &:hover::before,
	  &:hover::after {
		transform: scale3d(1, 1, 1); // Show full-size
		transition: transform 0.1s;
	  }
	}

  }
}

.nav-primary {

  @include breakpoint(0, md) {
	height: 0;
	padding-top: 100px;
  }
  @include breakpoint(md) {
	height: auto;
	padding-top: $nav-padding-top;
  }

}

.sidenav-toggle {
  @include breakpoint(0, md) {
	display: none;
  }
  @include breakpoint(md) {
	position: absolute;
	display: block;
	left: $sidenav-width - $sidenav-width-collapse;
	width: $sidenav-width-collapse;
	height: $sidenav-width-collapse;
	top: 0;
	z-index: zIndex(site-nav);
	background-color: color(brand);
	padding: 9px;

	//transition: animation(speed, default) animation(ease);
  }

}

.sidenav-collapse #sidenav {
  .sidenav-toggle {
	left: 0;
  }
  .sidenav-toggle {
	transform: rotate(180deg);

	//transition: animation(speed, default) animation(ease);
  }
  .nav-icons li {
	padding-right: 0 !important;
	@include breakpoint(md) {
	  display: block;
	}
  }

  @include breakpoint(md) {
	.cart_count, .wishlist_count {
	  display: none;
	}
	.cart_count_active, .wishlist_count_active {
	  margin-left: 0 !important;
	}
  }
}

.brand {
  .logo-logotype {
	width: 80%;
	padding-top: 68px;
	height: auto;
  }
  .logo-star {
	padding-top: $sidenav-width-collapse + 32px;
	margin: auto;
	display: none;
	transition: animation(speed, default) animation(ease);
  }
}

#sub-nav {
  background-color: palette(purple);
  position: fixed;
  height: 100vh;
  width: $sidenav-width;
  left: $sidenav-width;
  top: 0;
  //z-index: zIndex(site-nav);
  z-index: 9999999999;

  padding-top: $nav-padding-top + 134px;
  ul {
	@include reset-ul;
  }
}

.sub-nav-hidden {
  display: none;
}

.sidenav-collapse {
  @include breakpoint(0, md) {
	#sidenav {
	  height: $sidenav-width-collapse;
	  min-height: $sidenav-width-collapse;
	  max-height: $sidenav-width-collapse;
	  .nav-icons {
		position: absolute;
		top: 0;
		width: 100%;
		li {
		  display: inline-block;
		  &:not(:last-child) {
			margin-right: space(md);
		  }
		}
	  }
	  .nav-icons {
		position: absolute;
		top: 0;
		width: 100%;
		li {
		  display: inline-block;
		}
	  }
	  .nav-main {
		display: none;
	  }
	  .brand {
		display: none;
	  }

	  .nav-primary {
		height: 0;
		padding-top: 0;
	  }
	}

  }

  @include breakpoint(md) {
	#sidenav {
	  min-width: $sidenav-width-collapse;
	  max-width: $sidenav-width-collapse;
	  .nav-main {
		display: none;
	  }
	  .nav-icons li {
		padding-right: 0;
		display: block;
		&:not(:last-child) {
		  padding-bottom: 1rem;
		}
		.sidenav-toggle-hamb {
		  display: block;
		}
	  }
	  .logo-logotype {
		display: none;
	  }
	  .logo-star {
		display: block;
		width: 70%;
		transition: animation(speed, default) animation(ease);
	  }

	  #sub-nav {
		left: $sidenav-width-collapse;
	  }
	  .brand {
		display: block;
	  }

	}
	#main-col {
	  width: calc(100% - #{$sidenav-width-collapse});
	  left: $sidenav-width-collapse;
	}
  }
}

.lang_switcher_polylang {
  @include breakpoint(0, md) {
	display: none;
  }
  position: absolute;
  top: 35px;
  right: 15px;
  z-index: z-index(language-switcher);
  select {
	background: url('../images/icons/icon-caret-down-green-transparent.png') no-repeat right;
	background-size: 1rem;
	background-position-x: 80%;
	background-color: color(brand);
	border-style: none;
	color: #fff;
	height: 30px;
	option {
	  //color: color(body, color);
	  height: 30px;
	}

	//option[value="sv"] {
	//	display: none;
	//}
  }
}

#sidenav .nav-main {
  .lang-item {
	@include breakpoint(md) {
	  display: none;
	}
	&.active a {
	  border-bottom: none;
	}
  }
  .lang-item-first {
	margin-top: 15px;
  }
}