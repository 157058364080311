.woocommerce-order-received {
  .cm-simple-form {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
	h3 {
	  @include copy--h3;
	  text-align: center;
	  text-transform: none;
	}
	.cm-button {
	  text-align: center;
	}
  }
}

p.woocommerce-thankyou-order-received {
  @include copy--h2;
}

.woocommerce-thankyou-order-details {
  li {
	margin-bottom: space(xs);
  }
}
table.order_details {
  th, td {
	padding: space(xs);
  }
  tbody {
	tr:nth-child(even) {
	  background: color(white);
	}
	tr:nth-child(odd) {
	  background: color(gray, lighter);
	}
  }
  tfoot {
	tr:nth-child(even) {
	  background: color(gray, lighter);
	}
	tr:nth-child(odd) {
	  background: color(white);
	}
  }
}