/* Search results */

.container--search-results {
  margin-top: $grid-gutter-width;
  h2 {
    margin-bottom: $grid-gutter-width;
    margin-left: $grid-gutter-width / 2;
  }
}

/* The Modal (background) */

/* Modal Content/Box */
.remodal {
  text-align: center;
  vertical-align: top;
  margin: 15% auto;
  width: 500px;
  #yith-ajaxsearchform .search-navigation {
	float: left;
  }
  #yith-searchsubmit {
	float: left;
  }
  #yith-s {
    width: calc(500px - 150px);
  }
  #yith-searchsubmit {
    width: 150px;
  }
  label {
	@include sr-only;
  }

  .autocomplete-suggestion {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: space(sm);
    height: 70px;
  }

  .yith_wcas_result_image img {
    height: 70px;
    width: 70px;
  }
  .autocomplete-suggestion .yith_wcas_result_content .title {
    @include copy--sm;
    line-height: 1.3;
    color: color(gray, dark);
  }

  .link-result {
    //padding-top: space(sm);
    font-weight: font-weight(paragraph, bold);
    text-align: center;
    text-transform: uppercase;
    color: color(gray);
  }

  @include breakpoint(0, md) {
    text-align: left;
    .search-navigation {
      width: 180px;
      margin-left: 15px;
    }
    #yith-s {
      width: 180px;
      //display: block;
      //clear: both;
      //float: left;
    }
    #yith-searchsubmit {
      //display: block;
      //position: fixed;
      //bottom: 10%;
      //clear: both;
      //float: none;
      width: 100px;
      //margin-left: 20px;
    }
    .autocomplete-suggestions {
      width: 280px !important;
    }
  }
}

