.yith-wcwl-share-title {
  @include copy--h2;
  text-transform: none;
  padding-bottom: space(md);
  padding-top: space(lg);
}

.yith-wcwl-share {
  li {
	margin-right: space(md);
  }
}

#yith-wcwl-popup-message {
  display: none !important;
}