.woocommerce-password-strength {
  @include alert;
  @include alert-error;
}

.single-product, .woocommerce-cart, .woocommerce-checkout, .page-id-2864  {
  #main-col {
	padding-top: 130px;
  }
}

.woocommerce-error, .woocommerce-info {
  @include alert;
}

.woocommerce-error {
  @include alert-error;
}

.woocommerce-info {
  @include alert-info;
}


dl.variation {
  @include copy--sm;
  p {
	@include copy--sm;
  }
  dd, dt {
	float: left;
  }
  dt {
	clear: both;
  }
  dd {
	padding-left: space(xs);
	margin-bottom: 0;
  }
}

.demo_store {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  @include copy--h4;
  color: white;
  background-color: #8a1f11;
  padding: 30px;
  opacity: 0.7;
}
/*

.yith-wcwl-add-button {
  position: relative;
  width: 100%;
  height: 0;
  .add_to_wishlist {
    position: absolute;
    font-size: 0;
    top: -25px;
    right: 5px;
    &::after {

      width: 100%;
      content: "";
      //position: absolute;
      width: 20px;
      height: 20px;
      background: url("/wp-content/themes/dfs16/dist/images/icons/icon-heart-dark.png") 0 0 no-repeat;
      background-size: contain;

      display: inline-block;
    }
  }

}

.yith-wcwl-wishlistaddedbrowse, .yith-wcwl-wishlistexistsbrowse {
  position: absolute;
  //display: inline;
  a, span {
    font-size: 0;
  }
  height: 0;

  top: -20px;
  right: 5px;
  &::after {

    width: 100%;
    content: "";
    //position: absolute;
    width: 20px;
    height: 20px;
    background: url("/wp-content/themes/dfs16/dist/images/icons/icon-heart-green.png") 0 0 no-repeat;
    background-size: contain;

    display: inline-block;
  }
}



*/






// Category tag

.woo_tags {

  a {
    display: inline-block;
    background-color: color(gray, lighter);
    color: palette(gray);
    padding-left: space(xs);
    padding-right: space(xs);
    margin-right: space(sm);
    text-transform: uppercase;
    font-size: 0.75rem;
    &:hover {
      background-color: palette(green);
      color: color(white);
    }
  }
}

.product_meta {
  margin-bottom: space(md);
}

.woo_tag {
  display: inline-block;
  background-color: color(gray, lighter);
  color: palette(gray);
  padding-left: space(xs);
  padding-right: space(xs);
  margin-top: space(sm);
  margin-bottom: space(xs);
  text-transform: uppercase;
  font-size: 0.75rem;
  height: 18px;
  a:hover {
    text-decoration: underline;
  }
  &.spacer {
    background-color: transparent;
  }
}

