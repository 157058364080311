footer {
  clear: both;
  //min-height: 300px;
  margin-bottom: 100px;
  .footer-main, .footer-copyright {
	@include divider;
  }
  .footer-copyright {
	color: color(muted);
	.logo-star {
	  height: 3rem;
	  width: auto;
	  margin-right: 3rem;
	}
  }
  .footer-statement {
	display: none;
	margin-top: space(xl);
	color: color(green);
	@include copy--lg;
	line-height: 1;
	span {
	  font-weight: font-weight(body, bold);
	}
  }
}