// Nav

.nav a {
  font-weight: font-weight(paragraph, semi-bold);
}


// Headings
h1, .copy--h1 {
  @include copy--h1;
  @include baseline(heading, h1, $type-heading, 3);
  & + * {
    @include trailer(heading, h1, $type-heading, 3, 2);
  }
}

h2, .copy--h2 {
  @include copy--h2;
  @include baseline(heading, h2, $type-heading, 1.75);
  & + * {
    @include trailer(heading, h2, $type-heading, 1.75);
  }
}

h3, .copy--h3 {
  @include copy--h3;
  @include baseline(heading, h3, $type-heading, 1.5);
  & + * {
    @include trailer(heading, h3, $type-heading, 1.5, 1);
  }
}

h4, .copy--h4 {
  @include copy--h4;
  @include baseline(heading, h4, $type-heading);
  text-transform: uppercase;
  & + * {
    @include trailer(heading, h4, $type-heading, 2, 1);
  }
}

h5, .copy--h5 {
  @include copy--h5;
  @include baseline(heading, h5, $type-heading);
}

h6, .copy--h6 {
  @include copy--h6;
  // @include baseline(heading, h5, $type-heading);
  font-weight: font-weight(heading, semi-bold);
  & + * {
    @include trailer(heading, h5, $type-heading, 2, 1);
  }
}

.nav-title {
  @include copy--h5;
  @include baseline(heading, h5, $type-heading, 1.5);
  font-weight: font-weight(heading, semi-bold);
}

.copy--h6 {
  @include copy--h6;
  @include baseline(heading, h6, $type-heading);

  & + * {
    @include trailer(heading, h6, $type-heading, 2, 1);
  }
}

.table-heading {
  @include copy--sm;
  font-weight: font-weight(paragraph, semi-bold);
}

// Paragraph

p {
  @include copy--base;
  & + * {
    @include trailer(paragraph, body, $type-heading, 2, 2);
  }
}

.copy--lead, .lead {
  @include copy--lead;
  @include baseline(paragraph, lg, $type-body, 1.5);
  & + * {
    @include trailer(paragraph, lg, $type-body, 1.5, 2);
  }
}

.copy--sm, .copy--small {
  @include copy--sm;
  @include baseline(paragraph, sm, $type-body, 1);
  & + * {
    @include trailer(paragraph, sm, $type-body, 1, 1);
  }
}

.copy--xs {
  @include copy--xs;
  @include baseline(paragraph, xs, $type-body, 1);
  & + * {
    @include trailer(paragraph, xs, $type-body, 1, 1);
  }
}


// Labels

.label-primary {
  @include copy--lg;
  font-weight: font-weight(paragraph, semi-bold);
  color: color(primary);
}

.label-heading {
  font-weight: font-weight(paragraph, semi-bold);
  & + * {
    @include trailer(paragraph, body, $type-heading);
  }
}

.label-info {
  @include copy--sm;
  color: color(muted);
}

.detail {
  @include copy--sm;
  color: color(secondary);
  font-weight: font-weight(paragraph, semi-bold);
}

.value-detail {
  color: color(secondary);
  font-weight: font-weight(paragraph, semi-bold);
}

.dl-summary {
  @include copy--sm;
  color: darken(color(gray), 20%);

  > dt {
    font-weight: font-weight(paragraph, semi-bold);
  }

  a {
    font-weight: font-weight(paragraph, semi-bold);
    color: color(primary);
  }
}
