
html {
  // Sets a specific default `font-size` for user with `rem` type scales.
  @include rootsize();
  // As a side-effect of setting the @viewport above,
  // IE11 & Edge make the scrollbar overlap the content and automatically hide itself when not in use.
  // Unfortunately, the auto-showing of the scrollbar is sometimes too sensitive,
  // thus making it hard to click on stuff near the right edge of the page.
  // So we add this style to force IE11 & Edge to use a "normal", non-overlapping, non-auto-hiding scrollbar.
  // See https://github.com/twbs/bootstrap/issues/18543
  -ms-overflow-style: scrollbar;
  // Changes the default tap highlight to be completely transparent in iOS.
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  @include font-size(paragraph, body);
  // Make the `body` use the `font-size-root`
  font-family: font-family(paragraph);
  font-style: normal;
  font-weight: font-weight(paragraph, normal);
  line-height: $base-line-height;

  color: color(body, color);
  background-color: color(body, bg);
}

hr {
  border: 0;
  border-top: $border-height-thin solid currentColor;
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.content-section {
  border-top: 0.4rem solid color(primary);
}


$sidenav-width: 260px;
$sidenav-width-collapse: 50px;


#sidenav {
  width: inherit;
  min-width: $sidenav-width;
  max-width: $sidenav-width;
  position: fixed;

  min-height: 100vh;
  background-color: palette(purple, dark);
  background-image: url('../images/pattern@2x.png');
  background-size: 91px 52px;
  background-position: 8px 17px;
  background-repeat: repeat;

  //transition: animation(speed, default) animation(ease);

  @include breakpoint(0, md) {
    z-index: z-index(mobile-nav);
    background-image: none;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}


#main-col {
  @include breakpoint(0, md) {
    padding-top: $sidenav-width-collapse;
  }

  @include breakpoint(md) {
    position: absolute;
    width: calc(100% - #{$sidenav-width});
    min-height: 100vh;
    left: $sidenav-width;
  }

  .main-content {
    margin-top: space(lg);
  }
  //transition: left animation(speed, default) animation(ease);


  //padding-left: space(md);
  //padding-right: space(md);
  //margin-left: 0;
  //margin-right: 0;
}


.sidenav-collapse {

}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

footer {
  position: relative;
  width: 100%;
  clear: both;
}

@mixin divider {
  border-top: 1px solid palette(gray, light);
  margin-top: 50px;
  padding-top: 50px;
}

body.sidenav-transition {
  #main-col {
    transition: animation(speed, default) animation(ease);
  }
  #sidenav {
    transition: animation(speed, default) animation(ease);
  }
  .sidenav-toggle {
    transition: animation(speed, default) animation(ease);
  }
  .sidenav-collapse {
    .sidenav-toggle {
      transition: animation(speed, default) animation(ease);
    }
  }
}