@mixin copy-heading {
  font-family: font-family(heading);
  font-weight: font-weight(heading, bold);
}

@mixin copy--h1 {
  @include copy-heading;
  @include font-size(heading, h1);
  color: palette(gray, dark);
  letter-spacing: -0.05rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@mixin copy--h2 {
  @include copy-heading;
  @include font-size(heading, h2);
  color: palette(gray, dark);
  margin-top: 1rem;
  margin-bottom: 2rem;
  letter-spacing: 0;
  line-height: 2rem;
}

@mixin copy--h3 {
  @include copy-heading;
  @include font-size(heading, h3);
  color: palette(gray, base);
  margin-top: 1rem;
  letter-spacing: 0;
}

@mixin copy--h4 {
  @include copy-heading;
  @include font-size(heading, h4);
  color: palette(gray, base);
  text-transform: uppercase;
  letter-spacing: 0;
}

@mixin copy--h5 {
  @include copy--h4;
  text-transform: none;
}

@mixin copy--h6 {
  @include copy--h4;
  text-transform: none;
}

// Paragraph

@mixin copy--base {
  @include font-size(paragraph, body);
  a {
    color: color(primary);
    text-decoration: none;
    @include hover-focus {
      color: color(primary, dark);
      text-decoration: none;
    }
    &:focus {
      @include tab-focus-none();
    }
  }
}

@mixin copy--lg {
  @include font-size(paragraph, lg);
}

@mixin copy--lead {
  @include copy--lg;
}

@mixin copy--sm {
  @include font-size(paragraph, sm);
}

@mixin copy--xs {
  @include font-size(paragraph, xs);
}
