.pinboard {
  h2 {
	@include copy--h2;
	font-weight: font-weight(heading, bold);
  }
}

.pinboard--products, .pinboard--category {
  h2 {
	margin-bottom: 0;
	margin-top: 2rem;
  }
}

.hero {
  height: 350px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .overlay {
	width: 100%;
	height: 100%;
	background: rgba(70, 72, 82, 0.3);
  }
  .hero--content {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
  }
  h2 {
	//@include copy--h1;
	font-size: 52px;
	font-weight: font-weight(heading, bold);
	color: color(white);
	line-height: 1;
  }
  p {
	color: color(white);
	padding-left: space(xl);
	padding-right: space(xl);
  }
  .btn {
	margin-top: space(md);
  }
  &.box--height-auto {
	height: auto;
  }
  &.box--bg-black {
	background-color: palette(black);
	color: palette(white);
  }
}

.block {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &.box--bg-black {
	background-color: palette(black);
	h3, p {
	  color: palette(white);
	}
  }
}

.box--align-center {

  .col {
	@include breakpoint(md) {
	  @include make-col-span(8);
	  @include make-col-push(2);
	}
  }

  .cm-simple-form {
	text-align: center;
    max-width: none;
    padding-bottom: space(md);
  }
  .cm-simple-form .pre-info {
    text-align: center;
  }
  .cm-email {
    width: 300px;
  }
}

.pinboard--category {
  height: 100%;
  min-height: 300px;
  position: relative;
  background-size: cover;

  @include aspect-ratio(1,1);
  @include make-col();
  @include make-col-span(12);
  @include breakpoint(md) {
	@include make-col-span(12);
	.sidenav-collapse & {
	  @include make-col-span(6);
	}
  }
  @include breakpoint(lg) {
	@include make-col-span(6);
	.sidenav-collapse & {
	  @include make-col-span(3);
	}
  }

  @include breakpoint(1400) {
	@include make-col-span(3);
  }


  .pinboard--category-overlay {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: rgba(0, 0, 0, 0.3);
	transition: all animation(speed, fast) animation(ease);
	&:hover {
	  opacity: 1;
	  transition: all animation(speed) animation(ease);
	}

	//@include breakpoint(lg) {
	//  padding: space(lg);
	//}
  }
  .pinboard--category-content {
	position: absolute;
	left: space(lg);
	bottom: space(lg);
	padding-right: space(md);

	h2, p {
	  color: color(white);
	}
	p {
	  font-style: italic;
	  font-weight: font-weight(paragraph, base);
	  @include font-size(paragraph, lg);
	}
  }

  $font-size: 24px;
  $line-height: 1.3;
  $lines-to-show: 2;

  .pinboard--category-description {
	margin-bottom: space(sm);
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
	font-size: $font-size;
	line-height: $line-height;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
  }
}

.page-template-template-story {

}