// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include make-container();

  // For each breakpoint, define the maximum width of the container in a media query
  @each $key, $value in $breakpoints {
    @if $value > 0 {
      @if map-get($container-max-widths, $key) > 0 {
        @include breakpoint($key) {
          max-width: (map-get($container-max-widths, $key) + $base-font-size) / $base-font-size * 1em;
        }
      }
    }
  }

}

@each $key, $value in $breakpoints {
  @if $value > 0 {
    @if map-get($container-max-widths, $key) > 0 {
      .container#{$at}#{$key} {
        @include make-container();
        @each $key2, $value2 in $breakpoints {
          @if $value2 > 0 {
            @if map-get($container-max-widths, $key2) > map-get($container-max-widths, $key) {
              @include breakpoint($key2) {
                max-width: (map-get($container-max-widths, $key2) + $base-font-size) / $base-font-size * 1em;
              }
            }
          }
        }

      }
    }
  }
}

@each $key, $value in $breakpoints {
  @if $value > 0 {
    @if map-get($container-max-widths, $key) > 0 {
      .container--#{$key} {
        @include breakpoint($key) {
          max-width: (map-get($container-max-widths, $key) + $base-font-size) / $base-font-size * 1em;
        }
      }
    }
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include make-container();
}

// Row
//
// Rows contain and clear the floats of your columns.
@mixin row {
  @include make-row();
}

@mixin row-size($grid-width: $grid-gutter-width) {
  @include make-row($grid-width);
  > .col {
    padding-left: ($grid-width / 2);
    padding-right: ($grid-width / 2);
  }
}

.row {
  @include make-row();
}

.row-fluid {
  @include row-size( 0);
}

.row-sm {
  @include row-size( space(sm) * 2 );
}

.row-xs {
  @include row-size( space(xs) * 2 );
}


// Columns
//
// Common styles for small and large grid columns

@mixin col($width: false, $breakpoint: false, $grid-width: $grid-gutter-width  ) {
  @if $width == false or $breakpoint == false {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($grid-width / 2);
    padding-right: ($grid-width / 2);
  }

  @else {
    @include breakpoint($breakpoint) {
      @include make-col-span($width);
      float: left;
    }
  }
}

.col {
  @include col;
}

@include make-grid-columns();
