//
// Visibility utilities
//

.u-scroll-disabled {
  overflow: hidden;
}

.u-invisible {
  visibility: hidden !important;
}

// Responsive visibility utilities
.u-hidden {
  display: none !important;
}

@each $breakpoint in map-keys($breakpoints) {
  .u-hidden#{$at}#{$breakpoint}-up {
    @include breakpoint($breakpoint) {
      display: none !important;
    }
  }
  .u-hidden#{$at}#{$breakpoint}-down {
    @include breakpoint(0, $breakpoint) {
      display: none !important;
    }
  }

}

.u-hidden#{$at}xxs {
  @include breakpoint('xxs', 'xs') {
    display: none !important;
  }
}

.u-hidden#{$at}xs {
  @include breakpoint('xs', 'sm') {
    display: none !important;
  }
}

.u-hidden#{$at}sm {
  @include breakpoint('sm', 'md') {
    display: none !important;
  }
}

.u-hidden#{$at}md {
  @include breakpoint('md', 'lg') {
    display: none !important;
  }
}

.u-hidden#{$at}lg {
  @include breakpoint('lg', 'xl') {
    display: none !important;
  }
}

.u-hidden#{$at}xl {
  @include breakpoint('xl') {
    display: none !important;
  }
}
