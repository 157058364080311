// Campaign monitor form
.cm-simple-form {
  .cm-field-row, input, button {
	//float: left;
	display: inline-block;
	clear: none;
  }
  .cm-field-row.cm-button {
	padding-top: 0;
	width: auto;
  }
  .cm-field-row button.cm-submit {
	padding: 10px;
  }
  h3 {
	@include font-size(heading, h2);
	color: palette(gray, dark);
  }
}
.cm-form-error {
  p {
	@include alert;
	@include alert-white;
	background: none;
	margin-bottom: 2rem;
	@include copy--lg;
  }
}