body.woocommerce-checkout {
  .page-header {
	text-align: center;
  }

  div.woocommerce {
	width: 90%;
	max-width: 800px;
	margin: auto;
  }
  .select2-container .select2-choice {
	@include form-field__input;
	@include form-field__input--sm;
	width: 200px;
	display: inline-block;
  }

  h3 {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: font-weight(paragraph, bold);
	padding-bottom: space(sm);
	//color: color(muted);
  }

  .clear {
	display: none;
  }
  //form {
	//input{
	//  margin-right: space(md);
	//}
  //}

  .login {
	> p:first-of-type {
	  margin-bottom: space(md);
	}
	.lost_password {
	  clear: both;
	  margin-bottom: space(md);
	}
	.form-row {
	  width: 33%;
	}
  }



  input[type="text"], input[type="password"], input[type="number"], input[type="email"], input[type="tel"] {
	width: 100%;
  }

  .form-row {
	float: left;
	width: 25%;
	margin-top: 0;

	@include breakpoint(0, md) {
		width: 100%;
	}

  }

  #billing_company_field, #billing_email_field, #billing_phone_field {
	width: 50%;

	@include breakpoint(0, md) {
		width: 100%;
	}

  }

  #billing_address_1_field, #billing_address_2_field {
	clear: both;
	width: 100%;
  }

  #billing_city_field, #billing_state_field, #billing_postcode_field  {
	width: 50%;

	@include breakpoint(0, md) {
		width: 100%;
	}

  }

  #form_actions, .create-account {
	clear: both;
  }

  #billing_country_field, #order_comments_field {
	label {
	  //color: palette(purple, light);
	  font-weight: font-weight(paragraph, bold);
	}
  }

  #billing_country_field {
	margin-bottom: space(sm);
  }

  #order_comments_field {
	width: 100%;
  }

  .create-account {
	width: 100%;
  }

  .select2-chosen {
	//color: palette(purple, light);
  }

  .woocommerce_checkout_coupon {
	@include clearfix;
  }


  .woocommerce_checkout_coupon {
	//display: none !important;
  }

  #order_review {
	padding-bottom: space(md);
	width: 100%;
	.place-order {
	  width: 100%;
	}
	.terms {
	  width: 100%;
	}
  }
  .woocommerce-shipping-fields {
	clear: both;
	h3 {
	  //display: none;
	}
	p {
	  width: 25%;
	}
	#shipping_company_field, #shipping_postcode_field, #shipping_city_field {
	  width: 50%;

	  @include breakpoint(0, md) {
	  	width: 100%;
	  }

	}
	#shipping_address_1_field, #shipping_country_field {
	  clear: both;
	}

	#shipping_country_field {
	  margin-bottom: space(sm);
	}

	#shipping_address_1_field, #shipping_address_2_field {
	  width: 100%;
	}
  }



  .woocommerce-info {
	padding-bottom: space(md);
	a {
	  font-weight: font-weight(paragraph, bold);
	  //color: palette(purple, light);
	}
  }

  .actions a {
	@include btn;
	
	@include breakpoint(0, md) {
		padding: 0.20rem 0.4rem;
	}
  }

	.woocommerce-info {
		clear: both;
	}
}

.woocommerce-checkout-review-order-table {
  width: 100%;
  td.product-name {
	width: 50%;
  }
  td.product-total {
	width: 50%;
  }
}

#payment {
  margin-top: space(md);
}

.woocommerce-thankyou-order-received {
  @include copy--lg;
}

.woocommerce-thankyou-order-details {
  @include reset-ul;
}

.place-order .woocommerce-terms-and-conditions {
	@include alert;
	@include alert-info;
}

.woocommerce-checkout-payment {
	.form-row {
		clear: both;
	}
}

.payment_box {
	@include alert;
	@include alert-green;
}

.wc_payment_method {
	margin-top: 1.5rem;
}

.wc-terms-and-conditions {
	padding-top: 1.5rem;
}

select.klarna_part_payment_pclass {
	//width: 400px !important;
	//max-width: 100%;
}

.wc_payment_method {
	.form-row {
		width: 50% !important;
		padding-top: 0.5rem;
	}
	img {
		margin-left: 0.5rem;
	}
}

#wizard .payment_methods li input[type=text] {
	margin-top: 0 !important;
}

a.about_paypal {
	margin-left: 1rem;
	font-style: italic;
	&:focus, &:hover {
		cursor: hand !important;
		text-decoration: underline;
	}

}