
input[type="checkbox"], input[type="radio"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"], input[type="radio"], input[type="checkbox"] + label, input[type="radio"] + label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

input[type="checkbox"] + label, input[type="radio"] + label, #subscribe_to_newsletter_field > label {
  position: relative;
}

input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  content: '';
  background: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  padding: space(xs) / 2;
  margin-right: space(sm);
  text-align: center;
}

input[type="checkbox"]:checked + label:before {
  background: palette(green);
  box-shadow: inset 0px 0px 0px 4px #fff;
  outline: 0;
}

input[type="radio"] + label:before {
  border-radius: 50%;
}

input[type="radio"]:checked + label:before {
  background: palette(green);
  box-shadow: inset 0px 0px 0px 4px #fff;
}

input[type="checkbox"]:focus + label, input[type="radio"]:focus + label {
  outline: 1px solid #ddd; /* focus style */
}