$scale-heading: (
  xxs: (
    h1: 56,
    h2: 32,
    h3: 26,
    h4: 16,
    h5: 16,
    h6: 16,
  )
);

$scale-paragraph: (
  xxs: (
    lg: 24,
    body: $base-font-size, // 16
    sm: 14,
    xs: 12
  )
);

$modular-scale: (
  heading: $scale-heading,
  paragraph: $scale-paragraph
);

$rootsizes: (
  xxs: $base-font-size,   // 24px line-height body text
  md: $base-font-size,
);

$base-line-height: 1.5;

// Add typefaces here.
// Add weight and style details too.
// ! Set cap height to set to the baseline.
$type-body: (
  font-family: 'proxima-nova, sans-serif',
  cap-height: 0.69,
  normal: 400,
  bold: 700
);

$type-heading: (
    font-family: 'proxima-nova, sans-serif',
    cap-height: 0.69,
    bold: 700
);


@function font-weight($type, $prop: normal) {
  $type: $type-body;

  @return map-get($type, $prop);
}

@function font-family($type) {
  $type: $type-body;

  @return unquote(map-get($type, font-family));
}
