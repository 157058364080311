
.social-media-sharing {
    padding-top: 28px;
    padding-bottom: 28px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 28px;
    font-weight: bold;
    color: #4D4D4D;

    .sharetext {
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        line-height: 42px;
        padding-right: 20px;
    }

    .icon {
      vertical-align: middle;
      width: 42px;
      height: 42px;
      margin-right: 14px;
      display: inline-block;

      img.svg {
        display: none;
      }

      svg {                  
        width: 42px;
        max-width: 100%;
        max-height: 42px;
        height: auto;

        .cls-1 {
          fill: #8dcfa5;
        }
      }

    }

    .icon:hover svg .cls-1 {
      fill: #6ba881;
    }

}

.blog-archive
{
  .post-excerpt {
    font-size: 20px;
  }

  .read-full-article
  {
    float: right;
    margin-top: -5px;

    a {
      text-transform: uppercase;
    }
  }

  .load_more{ 
    display: block; 
    clear: both; 
    position: relative;
  }

  .load_more a, .load_more .loader{ 
    display: block; 
    height: 50px; 
    font-size: 16px; 
    font-weight: bold; 
    text-align: center;
    line-height: 50px;
    border: 3px solid #afe3e9;
    text-transform: uppercase;
    color: #afe3e9;
    overflow: hidden; 
    position: relative; 
  }

  .load_more{ 
    display: block; 
    clear: both; 
    position: relative; 
  }

  .load_more a:hover{ 
    background-color: #afe3e9;
    color: #fff; 
  }

}

.single-blog-post {

  h1 {
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
    letter-spacing: 0;
    line-height: 1.75rem;
    padding-top: .18501rem;
  }

  p:first-child {
    font-size: 20px;
  }

  .article-date {
    font-style: italic;
    font-size: 14px;
    color: #4D4D4D;
  }

  .article-image
  {
    margin-bottom: 30px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .article-keywords {
    margin-top: 55px;
    margin-bottom: 35px;
    text-transform: uppercase;
    font-size: 16px;
    color: #4d4d4d;
    font-weight: bold;

    .text {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 5px;
    }

    .keyword {
      margin-right: 9px;
      display: inline-block;

      a {
        width: auto;
        background-color: #f4f4f4;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        font-weight: 400;
      }

      a:hover {
        background-color: #d9d9d9;
      }
    }

  }

  .product-lifts {

    max-width: 1200px;
    width: 100%;

    .product-lift {

      padding: 0px;

      ul.products li.product>.product-wrapper {
        width: 100%;
        padding: 0px;
        margin-bottom: 0px;
      }

      ul.products li.product>.product-wrapper .product-wrapper-inner:hover {
        transform: initial;
        -webkit-transform: initial;
      }

      ul.products li.product>.product-wrapper .product-name {
        height: auto;
      }

    }
  }

}

// ----------------------
// RESPONSIVE SETTINGS 
// ----------------------

@media (max-width: 768px) {

  .social-media-sharing {
    .sharetext {
      padding-right: 10px;
    }
    .icon {
      margin-right: 5px;
    }
  }

  .blog-archive .read-full-article {
      float: none;
      margin-top: 20px;

      a {
        width: 100%;
      }
  }

}
