// Breakpoint

// Either set one to one of the breakpoint variables, or use a custom value for minor breakpoints.
// Defaults to min-width, but both min->max and max-width are available too.
// Parts based on https://gist.github.com/timknight/03e6335b8816aa534cf7

@mixin breakpoint($min: 0, $max: 0) {

  $typeMin: type-of($min);
  $typeMax: type-of($max);

  $valueMin: $min;
  $valueMax: $max;

  @if $typeMin == string {
    @if map-has-key($breakpoints, $min) {
      $valueMin: map-get($breakpoints, $min);
    }

    @else {
      @warn "Min: #{$min} is not a set breakpoint variable";
    }
  }

  @if $typeMax == string {
    @if map-has-key($breakpoints, $max) {
      $valueMax: map-get($breakpoints, $max);
    } @else {
      @warn "Max: #{$max} is not a set breakpoint variable";
    }
  }

  @if $valueMin > 0 or $valueMax > 0 {
    $query: "all" !default;

    @if $valueMin != 0 and $valueMax != 0 {
      $query: "(min-width: #{$valueMin / $base-unit-size * 1em}) and (max-width: #{($valueMax - 1) / $base-unit-size * 1em})";
    }

    @else if $valueMin != 0 and $valueMax == 0 {
      $query: "(min-width: #{$valueMin / $base-unit-size * 1em})";
    }

    @else if $valueMin == 0 and $valueMax != 0 {
      $query: "(max-width: #{($valueMax - 0.0001) / $base-unit-size * 1em})";
    }

    @media #{$query} {
      @content;
    }
  } @else {
    @content;
  }

}
