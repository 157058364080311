.nav {
  @include reset-ul;
}

.nav__item {
  display: block;
}

.nav__link {
  display: inline-block;
}

.nav--inline {
  .nav__item {
	display: inline-block;
  }
}
