.o-media {
  display: block;
}

  .o-media__image {
    float: left;
    margin-right: $grid-gutter-width-half;

    > img {
      display: block;
    }

  }

  .o-media__body {
    overflow: hidden;
    display: block;

    &,
    > :last-child {
      margin-bottom: 0;
    }

  }
