$space-y-xxs: $grid-gutter-width / 4;
$space-y-md: $grid-gutter-width;
$space-y-lg: $grid-gutter-width * 2;

$spacing-map: (
  y: (
    xs: $grid-gutter-width / 6,
    sm: $grid-gutter-width / 4,
    md: $grid-gutter-width / 2,
    base: $grid-gutter-width / 1.6,
    lg: $grid-gutter-width,
    xl: $grid-gutter-width * 1.5,
    xxl: $grid-gutter-width * 2
  )
);

@function space($size, $direction: y) {
  @if map-has-key($spacing-map, $direction) {
    @if map-has-key(map-get($spacing-map, $direction), $size) {
      @return map-get(map-get($spacing-map, $direction), $size);
    }
  }
}

$stack-map: (
  y: (
    xs: $grid-gutter-width / 4,
    sm: $grid-gutter-width / 2,
    md: $grid-gutter-width,
    lg: $grid-gutter-width * 2,
    xl: $grid-gutter-width * 3,
    xxl: $grid-gutter-width * 4
  )
);

@function stack($size, $direction: y) {
  @if map-has-key($stack-map, $direction) {
    @if map-has-key(map-get($stack-map, $direction), $size) {
      @return map-get(map-get($stack-map, $direction), $size);
    }
  }
}
