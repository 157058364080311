.filters {
  height: 47.5px;
  clear: right;
  border-top: 1px solid palette(gray, light);
  border-bottom: 1px solid palette(gray, light);

  @include copy--sm;

  color: palette(gray);
  @include breakpoint(0, md) {
    height: auto;
    padding-bottom: 50px;
  }
}

.facetwp-template {
  //padding-left: $grid-gutter-width-half;
  //padding-right: $grid-gutter-width-half;
}

.fs-label-wrap {
  padding: 10px;
  border-top: none !important;
  border-left: none !important;
  border-bottom: none !important;
  text-transform: uppercase;
  font-weight: font-weight(body, normal) !important;
}

.facetwp-type-fselect {
  float: left;
  width: 220px;
}

.fs-dropdown {
  margin-top: 0 !important;
  //border-top: none !important;
  @include breakpoint(0, lg) {
    width: 100% !important;
  }
}

.facetwp-loading {
  margin-left: 10px;
  margin-top: 13px;
  margin-bottom: 16.5px;
}

.facetwp-facet {
  margin-bottom: 0 !important;
  @include breakpoint(0, lg) {
    width: 100%;
    border-bottom: 1px solid palette(gray, light);
    .fs-wrap {
      width: 100% !important;
    }
  }
}

.fs-arrow {
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-top: 5px solid palette(green) !important;
  right: 10px !important;
  //display: none;
/*  display: inline-block;
  content: '';
  border: 0 !important;
  margin: 0 !important;
  position: static !important;*/
}

.fs-label:after {
/*  position: absolute;
  content: "";
  background-image: url(../images/icons/caret-down.png);
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
  top: 0;
  right: 20px;
  fill: palette(gray);*/
  @include caret;
  color: palette(gray, dark);
}

.fs-option-label {
  padding: 10px;
  text-transform: capitalize;
}

.fs-option:hover, .fs-option.selected {
  background-color: palette(green);
  color: palette(white);
}

.fs-search, .fs-option {
  padding: 6px 8px !important;
}

.fs-search input {
  padding: 0;
}

.fs-label-wrap {
  cursor: pointer !important;
}

.fs-dropdown .fs-options {
  max-height: 400px !important;
  overflow: auto;
}

