body.woocommerce-account {
  p.form-row {
	@include trailer(paragraph, body, $type-heading, 2, 2);
  }
  #customer_login .input-text {
	width: 100%;
  }

  div.u-columns {
	@include make-row();

	&.col2-set {
	  .col-1, .col-2 {
		margin-bottom: space(lg);
	  }
	  @include breakpoint(md, xl) {
		.col-1, .col-2 {
		  @include make-col();
		  @include make-col-span(6);
		}
	  }

	  @include breakpoint(xl) {
		.col-1, .col-2 {
		  @include make-col();
		  @include make-col-span(4);
		}
		.col-2 {
		  @include make-col-offset(1);
		}
	  }
	}
  }
}


body.woocommerce-account.logged-in {
  nav > ul {
	@include reset-ul;
	li.is-active, a:hover {
	  text-decoration: underline;
	}
	li:first-of-type {
	  font-weight: font-weight(paragraph, bold);
	}
  }
  div.woocommerce {
	margin-top: space(lg);
	@include breakpoint(md) {
	  @include make-row();
	  .woocommerce-MyAccount-navigation {
		@include make-col();
		@include make-col-span(3);
	  }

	  .woocommerce-MyAccount-content {
		@include make-col();
		@include make-col-span(9);
	  }
	}
  }
  .woocommerce-Button {
	margin-right: space(md);
  }

  .woocommerce-EditAccountForm {
	.input-text {
	  @include breakpoint(0, md) {
		width: 100%;
	  }
	  @include breakpoint(md) {
		width: 400px;
	  }
	}
  }
  .woocommerce-Address-title {
	text-align: left;
  }
}