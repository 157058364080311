.s-styleguide {
  span {
    display: inline;
	font-size: 14px;
	font-weight: normal;
	color: color(muted);
	text-transform: none;
  }
  p span {
	display: block;
  }
  .swatch {
	width: 200px;
	height: 60px;
	position: relative;
	span {
	  position: absolute;
	  @include u-align();
	  color: color(white);
	  text-align: center;
	}
  }
  .photo-bg {
	background-image: url('/wp-content/themes/dfs16/dist/images/dev-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	width: 400px;
	height: 300px;

	position: relative;
	span {
	  position: absolute;
	  @include u-align();
	  text-align: center;
	}
  }
}