.single-product .product {
  .product-wrapper {
	@include clearfix();
	//@include make-row();
	//padding-left: 10px;

	.images {
	  @include make-col();
	  @include make-col-span(6);
	  @include breakpoint(0, sm) {
		@include make-col-span(10);
		@include make-col-offset(1);
	  }
	  @include breakpoint(sm, md) {
		@include make-col-span(8);
		@include make-col-offset(2);
	  }
	}
	.summary {
	  @include make-col();
	  @include make-col-span(6);
	  @include breakpoint(0, md) {
		clear: both;
		@include make-col-span(12);
	  }
	}
	@include breakpoint(xl) {
	  .images {
		@include make-col(50px);
		@include make-col-span(5);
	  }
	  .summary {
		@include make-col(50px);
		@include make-col-span(7);
	  }
	}
	.onsale {
	  display: none;
	}
  }
  .qty {
	display: none;
  }

  .summary > div {
	margin-bottom: 2rem;
  }

  .summary {
	h3 {
	  @include copy--h4;
	  color: color(muted);
	  //color: color(muted);
	}
	h1 {
	  @include copy--h2;
	  padding-top: 0;
	  //line-height: 2rem;
	  span {
		display: block;
		clear: both;
		@include copy--h4;
		text-transform: none;
		margin-top: 0;
		color: inherit;
		font-weight: font-weight(paragraph, normal);
	  }
	}
	.yith-wcwl-wishlistaddedbrowse.show, .yith-wcwl-wishlistexistsbrowse.show {
	  @include alert;
	  @include alert-info;
	  max-width: 400px;
	  margin-top: 0;
	}
	.single_variation_wrap, .yith-wcwl-add-to-wishlist {
	  //display: inline-block;
	}
	.yith-wcwl-add-to-wishlist {
	  //margin-left: space(md);
	}
  }

  form.cart, .woocommerce-message, .added_to_cart {
	display: inline-block;
  }

  form.cart {
	padding-right: space(md);
  }

  button, .button, .added_to_cart {
	margin-bottom: space(md);
  }

  .woocommerce-message .button {
	@include btn--secondary;
  }

  .added_to_cart {
	@include btn;
	@include btn--secondary;
  }

  .single_add_to_cart_button.added {
	margin-right: space(md);
  }

  .wc-tabs-wrapper {
	@include clearfix;
	@include divider;
	//@include make-row();
	padding-left: space(lg);
	padding-right: space(lg);
	.wc-tabs {
	  display: none;
	}
	.wc-tab {
	  // @include make-col();
	  //@include make-col-span(12);
	}
  }

  .related, .upsells {
	//@include make-row();
	padding-left: space(lg);
	padding-right: space(lg);
	@include divider;
	.products {
	  //@include make-col();
	  //@include make-col-span(12);
	}
	h2 {
	  font-weight: font-weight(body, bold);
	  //@include make-col();
	  //@include make-col-span(12);
	}
  }

  .woocommerce-main-image img {
	width: 100%;
	max-width: 100%;
	height: auto;
	padding: 5px;
  }

  .thumbnails.columns-3 img {
	width: calc(100% / 4);
	height: auto;
	padding: 5px;
  }

  .label {
	text-transform: uppercase;
  }
  .woocommerce-variation-price {
	min-height: 24px;
  }

  .summary .price {
	@include copy--lg;
  }

  .wc-forward:after {
	content: '';
	display: block;
	clear: both;
  }

  // Destroy table layout on variations

  table[class="variations"] {
	display: block;
	width: 100%;
	padding-bottom: space(md);
	tbody, tr, td {
	  display: block;
	  width: 100%;
	}
	label {
	  text-transform: uppercase;
	  font-weight: font-weight(paragraph, bold);
	  color: palette(gray);
	  padding-top: space(xs);
	}
  }
  .woocommerce-variation-price {
	padding-bottom: space(md);
  }
  .woocommerce-Tabs-panel h2 {
	color: palette(gray);
	text-transform: uppercase;
	@include font-size(paragraph, body);
	font-weight: font-weight(paragraph, bold);
	margin-bottom: space(sm);
  }

  .reset_variations {
	display: none !important;
  }

  .shop_attributes {
	th {
	  padding-right: space(sm);
	}
  }

  .delivery_estimate {
	text-transform: uppercase;
	font-weight: font-weight(paragraph, bold);
	color: color(green);
  }

  .stock.out-of-stock {
	@include alert;
	@include alert-error;
  }
  .woocommerce-variation-description {
	padding-bottom: space(sm);
  }

  .dfs-custom-size-fields {
	display: none;
	input {
	  @include form-field__input;
	  @include form-field__input--md;
	  width: 70px;
	}
	#dfs-custom-size-width {
	  margin-right: space(xs);
	}
	#dfs-custom-size-length {
	  margin-right: space(xs);
	  margin-left: space(xs);
	}
	span {
	  font-weight: font-weight(paragraph, bold);
	}
	.totalprice {
	  @include copy--lg;
	  padding-top: space(md);
	  //padding-bottom: space(md);
	}
	.regularprice, .normalprice, .saleprice {
	  display: inline-block;
	  margin-right: space(xs);
	}
  }
  .dfs-custom-size-fields + .woocommerce-variation {
	.woocommerce-variation-description {
	  padding-bottom: 0;
	}
  }

  // Customizations for WooCommerce product gallery flex slider thumbnails
  figure.woocommerce-product-gallery__wrapper {
  	img.attachment-shop_single {
  		width: 100% !important;
    	max-width: 100% !important;
    	height: auto !important;
    	padding: 5px;
    }
  }

  ol.flex-control-thumbs {
  	padding-left: 0px;

  	li {
  	  display: inline;
  	  cursor: pointer;
  	  list-style-type: none;

  	  img {
  	  	    width: 25%;
    		height: auto;
    		padding: 5px;
  	  }
  	}
  }

}