/* stylelint-disable */

/* Default Equations */
$animation-linear        : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$animation-ease          : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$animation-ease-in       : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$animation-ease-out      : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$animation-ease-in-out   : cubic-bezier(0.420, 0.000, 0.580, 1.000);

/* Penner Equations */
$animation-ease-circ-out  : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$animation-ease-circ-in   : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$animation-ease-cubic-out : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$animation-ease-cubic-in  : cubic-bezier(0.550, 0.055, 0.675, 0.190);

/* stylelint-enable */

/* Theme defaults */
$animation-default        : $animation-ease-cubic-out;
$animation-default-hard   : $animation-ease-circ-out;

$animation-map: (
  ease: (
    default: $animation-default,
    default-out: $animation-ease-cubic-in,
    hard: $animation-default-hard,
    hard-out: $animation-ease-circ-in
  ),

  speed: (
    default: 0.3s,
    fast: 0.15s,
    slow: 0.6s
  )
);

@function animation($prop, $value: default) {
  @if map-has-key($animation-map, $prop) {
    @return map-get(map-get($animation-map, $prop), $value);
  }
}
