// Credit: Nicolas Gallagher and SUIT CSS.

.o-embed-fluid {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

  .o-embed-fluid__item,
  .o-embed-fluid iframe,
  .o-embed-fluid embed,
  .o-embed-fluid object,
  .o-embed-fluid video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

.o-embed-fluid--21by9 {
  padding-bottom: percentage(9 / 21);
}

.o-embed-fluid--16by9 {
  padding-bottom: percentage(9 / 16);
}

.o-embed-fluid--4by3 {
  padding-bottom: percentage(3 / 4);
}

.o-embed-fluid--1by1 {
  padding-bottom: percentage(1 / 1);
}
