.form-field--switch {
  height: 2rem;

  .form-field__label {
    @include font-size(paragraph, body);
    font-weight: font-weight(paragraph, semi-bold);
    color: color(primary);
  }

  .form-field__input {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
}

.form-field__switch {
  position: relative;
  height: 1.5rem;
  width: 4rem;
  background-color: color(gray);
  border-radius: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: space(md);

  &::after {
    position: absolute;
    content: '';
    height: 2rem;
    width: 2rem;
    border-radius: 1.25rem;
    background-color: darken(color(gray), 30%);
    left: 0;
    top: 50%;
    transform: translate(-0.5rem, -50%);
    transition: transform animation(speed) animation(ease), background animation(speed) animation(ease);
    pointer-events: none;
  }
}

.form-field__input:checked + .form-field__switch::after {
  background-color: color(primary);
  transform: translate(2.5rem, -50%);
}
