/*

.form-row:not(.create-account):not(#billing_country_field):not(.create-account):not(#shipping_country_field):not(.wc-terms-and-conditions):not(.place-order):not(.rememberme):not(#subscribe_to_newsletter_field)
{
  @include form-field--pl;
  position: relative;
  padding-right: space(md);
  margin-bottom: space(sm);
}
*/


form.login, form.checkout_coupon, div.billing-tab-contents, form.checkout_giftcard {
  .form-row:not(.create-account):not(#billing_country_field):not(.create-account):not(#shipping_country_field):not(.wc-terms-and-conditions):not(.place-order):not(.rememberme):not(#subscribe_to_newsletter_field) {
    @include form-field--pl;
    position: relative;
    padding-right: space(md);
    margin-bottom: space(sm);
  }
}

input[type="text"], input[type="password"], input[type="number"], input[type="email"], input[type="tel"], input[type="search"] {
  @include form-field__input;
  @include form-field__input--md;
}

.woocommerce-billing-fields > p:not(#billing_country_field):not(.create-account):not(#subscribe_to_newsletter) {
  @include form-field--pl;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
  @include form-field__input;
  @include form-field__input--md;
}

input + label {
  outline: none !important;
}