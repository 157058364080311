@mixin dropdown__select {
  @include font-size(paragraph, lg);

  cursor: pointer;
  height: 100%;
  //left: 1rem;
  //right: 1rem;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .form-field--inline & {
	left: 0;
  }
  > option {
	padding-left: 1rem;
	background-color: #fff;
  }

  @include hover-focus {
	& + .form-field__input {
	  border-color: color(gray);
	}
  }

  &:focus + .form-control {
	@include tab-focus-none();
  }
}

@mixin form-field__dropdown {
  position: relative;
}

.form-field__dropdown {
  @include form-field__dropdown;
}

.dropdown__select {
  @include dropdown__select;
}


@mixin dropdown__icon {
  position: absolute;
  right: $grid-gutter-width-half;
  top: 0;
  transform: translate(0, -50%);
  top: 50%;
  color: $form-label-color;

  .form-field--embed & {
	color: currentColor;
	right: 0;
  }
}

.dropdown__icon {
  @include dropdown__icon;
}

@mixin caret {
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 0.05em;
  font-size: 85%;
  color: inherit;

  &::before,
  &::after {
	position: absolute;
	display: inline-block;
	width: 0;
	height: 0;
	left: 0;
	content: '';
	border-right: 0.5em solid transparent;
	border-left: 0.5em solid transparent;
  }

  /* Up */
  &::before {
	top: 0;
	border-bottom: 0.5em solid currentColor;
  }

  /* Down */
  &::after {
	border-top: 0.5em solid currentColor;
  }

  &::after {
	bottom: 0.2em;
  }
  &::before {
	display: none;
  }
}

.caret {
  @include caret;
}

.form-field--select.form-field--inline {
  .form-field__label {
	position: absolute;
  }
}

.form-field--select.form-field--pl {
  .form-field__input {
	height: 4.875rem;
  }
}
