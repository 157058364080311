.o-flag {
  display: table;
  width: 100%;
}

  .o-flag__figure,
  .o-flag__body {
    display: table-cell;
    vertical-align: middle;

    .o-flag--top > & {
      vertical-align: top;
    }

    .o-flag--bottom > & {
      vertical-align: bottom;
    }

  }

  .o-flag__figure {
    // padding-right: $grid-gutter-width-half;

    > img {
      display: block;
      max-width: none;
    }

  }

  .o-flag__body {
    width: 100%;

    &,
    > :last-child {
      margin-bottom: 0;
    }

  }



.o-flag--lg {
  .o-flag__image,
  .o-flag__figure {
    padding-right: $grid-gutter-width;
  }
}
