.o-list {
  @include reset-ul;
}

  .o-list__item {
    display: block;
  }

    .o-list__link {
      display: inline-block;
    }

.o-list--inline {
  .o-list__item {
    display: inline-block;
  }
}
