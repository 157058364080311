
.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after, #wizard{
  clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
  *zoom: 1;
}

form.checkout, .container-coupon-login-form{
  display:none;
}

.error-msg{
  //color: #8a1f11;
}

label.error-class{
  //color: #8a1f11 !important;
  display: block;
}

.terms-error{
  //color: #8a1f11 !important;
  clear: both;
  display: block;
}

#s2id_billing_state.invalid-state, #s2id_shipping_state.invalid-state{
  border: 1px solid #a00 !important;
}

.wizard > .content > .body input[type="radio"]{
  display: inline-block
}

.wizard .coupon-step.current .checkout_coupon{
  display: block !important
}

.wmc-loading-img{
  display: block;
  margin: 10px auto;
  text-align: center;
  border: none;
  border-radius: 0 !important;
  box-shadow: none !important
}

@media(max-width: 767px){
  .wizard > .steps > ul > li {
    width: 100% !important;
  }

  .actions.clearfix li {
    clear: none !important;
    display: inline-block;
    float: left;
  }
  #wizard .steps ul li{
    width: 100% !important;

  }
}


/****Elegant style of wizard **/
#wizard #place_order, .woocommerce-billing-fields > h3 {
  display: none
}

.woocommerce #payment div.form-row, .woocommerce-page #payment div.form-row {
  border-top: none;
  padding: 0
}

#ship-to-different-address {
  margin: 0
}

.woocommerce #payment ul.payment_methods, .woocommerce-page #payment ul.payment_methods {
  border-bottom: none;
  padding-bottom: 5px
}

.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}

.wizard a,
.tabcontrol a {
  outline: 0;
}

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0 !important;
}

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

/*
    Wizard
*/

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
  text-transform: uppercase;
  color: color(muted);
  border-color: color(muted);
  .current {
    color: color(blue);
    border-color: color(blue);
  }
}

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}

.wizard > .steps .number {
  display: inline-block;
  border: 2px solid;
  border-radius: 50%;
  //font-size: 32px;

  &:before,
  &:after {
    content:'\200B';
    display:inline-block;
    line-height:0px;

    padding-top:50%;
    padding-bottom:50%;
  }

  &:before {
    padding-left:8px;
  }
  &:after {
    padding-right:8px;
  }
}

.wizard.five-steps > .steps > ul > li {
  width: 20%;
}

.wizard.three-steps > .steps > ul > li {
  width: 33.33333%;
}

.wizard > .steps > ul > li {
  width: 25%;

}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
  margin-left: 0;
}

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}

.wizard.vertical .steps a, .wizard.vertical .steps a:hover, .wizard.vertical .steps a:active {
  text-align: left;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0;
  padding: 1em 1em;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  cursor: default;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {

}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {

}

.wizard > .content {
  display: block;
  margin: 0.5em;
  /*    min-height: 35em;*/
  overflow: hidden;
  position: relative;
  width: auto;
}

.login .form-row .button {
  border: none;
}

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}

.wizard > .content > .body {
  float: left;
  /*    position: absolute;*/

  height: 100%;
  padding: 0;
  width: 100%; /**Just to make each section responsive **/
}

/*.wizard span.number{display: none}*/

.wizard > .content > .body ul {
  list-style: disc !important;
}

.wizard > .content > .body ul > li {
  display: list-item;
}

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}


.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}

.wizard > .actions > ul {
  display: inline-block;
  text-align: right;

  @include breakpoint(0, md) {
    float: left;
  }

}

.wizard > .actions > ul > li {
  margin: 0 0.5em;

  @include breakpoint(0, md) {
    margin-bottom: 20px;
  }
}

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.wizard > .actions .disabled a {

}

.wizard > .loading {
}

.wizard > .loading .spinner {
}

/*
    Tabcontrol
*/

.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;

  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}

.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li > a {
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.tabcontrol > .content > .body ul {
  list-style: disc !important;
}

.tabcontrol > .content > .body ul > li {
  display: list-item;
}

#wizard ul.payment_methods li {
  list-style-type: none;
}

#wizard .payment_methods li input {
  float: left;
  margin-top: 9px !important;
  margin-right: 6px !important;
}

.woocommerce-checkout .checkout #payment {
  background: none;
}

/**********Media qUERIES ***/
/***For smart phones **/
@media(max-width: 767px) {
  .wizard > .steps > ul > li, .wizard.five-steps > .steps > ul > li {
	float: none;
	font-size: 14px;
	margin-left: 0;
	width: 100%;
  }

  .wizard > .steps .actions.clearfix ul li {
	float: right;
  }
  .wizard > .steps .actions.clearfix ul li:first-child {
	float: left;
  }

  .wizard.vertical > .steps, .wizard.vertical > .content {
	width: 100%;
	margin: 0;
	padding: 0;
	clear: both;
  }

  .wizard.vertical > .content {
	float: left;
	margin-bottom: 10px;
  }
  .wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
	text-align: left;
  }
}