$z-index-map: (
  "auto": auto,
  "below": -1,
  "default": 1,
  "header": 100,
  "mobile-nav": 95,
  "booking-bar": 20,
  "language-switcher": 120,
  "overlay": 200,

);

@function z-index($element, $offset: 0) {
  @if map-has-key($z-index-map, $element) {
    @return map-get($z-index-map, $element) + $offset;
  }
}
