// SCSS mixins
// ---------------------------------------

// Global variables used in mixins.

// List of rootsizes, breakpoints, and max-widths.
$sizes: map-values($rootsizes);
$points: map-values($breakpoints);
// $line-widths: map-values($measures);
$max-widths: map-values($container-max-widths);

// Root font-size in %, outputted in correct breakpoints.
@mixin rootsize {
  @each $item, $scale in $rootsizes {
    $get-size: map-get($rootsizes, $item);

    @include breakpoint($item) {
      font-size: $get-size / 16 * 100%;
    }
  }
}

// Value in scale in $modular-scale?
// Used in following fontsize mixin.
@function modular-scale($scale) {
  $map: map-get($modular-scale, $scale);

  @return $map;
}

@function in-modular-scale($scale, $key) {
  $output: map-has-key($scale, $key);

  @return $output;
}

// Font-size in rems. Either set per breakpoint or for all.
// Use values as you would for pixels i.e. 16 or use values from the modular scale.
@mixin font-size($scale, $fontsize, $breakpoint: 'all') {

  // Type of chosen variables.
  $font-value: type-of($fontsize);
  $break-value: type-of($breakpoint);
  $scale-map: modular-scale($scale);

  // Loop through breakpoints.
  @each $item, $scale in $scale-map {
    $breakpoint-in-scale: in-modular-scale($scale-map, $item);

    @if $breakpoint-in-scale == true {
      $breakpoint-scale: map-get($scale-map, $item);
      $size-in-scale: in-modular-scale($breakpoint-scale, $fontsize);

      @if $size-in-scale == true {
        $size: map-get($breakpoint-scale, $fontsize);

        @include breakpoint($item) {
          font-size: #{$size / map-get($rootsizes, $item)}rem;
        }
      }

    }

  }

}

// Advanced baseline magic.
// ! Read the README to help understand what is going on here.
// Parts based on https://gist.github.com/razwan/10662500
@mixin baseline($scale, $fontsize, $font, $lineheight: 2, $below: 2, $breakpoint: all) {
  // Type of chosen variables.
  $font-value: type-of($fontsize);
  $break-value: type-of($breakpoint);
  $scale-map: modular-scale($scale);

  // Cap height
  $cap-height: map-get($font, cap-height);

  // Check if value exists in scale.
  $in-scale: in-modular-scale($scale-map, xxs);
  // $font-map: modular-scale($scale-map, xxs);

  $font-map: map-get($scale-map, xxs);
  // Set the line-height.
  line-height: #{$lineheight}rem;

  @if $breakpoint == all {

    // If using a number for fontsize.
    @if $font-value == number {
      $rootsize: nth($sizes, 1);
      $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};

      margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;

      // Loop through breakpoints.
      @for $i from 2 through $points {
        $rootsize: nth($sizes, $i);
        $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
        $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};

        @include breakpoint(nth($points, $i)) {
          margin-bottom: #{$baseline-push}rem;
          padding-top: #{$baseline-shift}rem;
        }
      }

    // If using a variable from the scale for fontsize.
    } @else if $in-scale == true {
      $get-scale: $font-map;
      $get-size: map-get($get-scale, $fontsize);

      $rootsize: nth($sizes, 1);

      $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};

      // margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;

      // Loop through breakpoints.
      // @for $i from 2 through $points {
      //   $get-scale: map-get($modular-scale, scale-#{$i - 1});
      //   $get-size: map-get($get-scale, $fontsize);
      //   $rootsize: nth($sizes, $i);
      //
      //   $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
      //   $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};
      //
      //   @include breakpoint(nth($points, $i)) {
      //     margin-bottom: #{$baseline-push}rem;
      //     padding-top: #{$baseline-shift}rem;
      //   }
      // }

    } @else {
      @warn "#{$fontsize} is not a valid scale variable";
    }

  } @else {
    @warn "#{$breakpoint} is not valid to use as a breakpoint";
  }
}

@mixin trailer($scale, $fontsize, $font, $lineheight: 2, $below: 1, $breakpoint: all) {
  // Type of chosen variables.
  $font-value: type-of($fontsize);
  $break-value: type-of($breakpoint);
  $scale-map: modular-scale($scale);

  // Cap height
  $cap-height: map-get($font, cap-height);

  // Check if value exists in scale.
  $in-scale: in-modular-scale($scale-map, xxs);
  // $font-map: modular-scale($scale-map, xxs);

  $font-map: map-get($scale-map, xxs);
  // Set the line-height.
  @if $breakpoint == all {

    // If using a number for fontsize.
    @if $font-value == number {
      $rootsize: nth($sizes, 1);
      $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};

      margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;

      // Loop through breakpoints.
      @for $i from 2 through $points {
        $rootsize: nth($sizes, $i);
        $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
        $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};

        @include breakpoint(nth($points, $i)) {
          margin-bottom: #{$baseline-push}rem;
          padding-top: #{$baseline-shift}rem;
        }
      }

    // If using a variable from the scale for fontsize.
    } @else if $in-scale == true {
      $get-scale: $font-map;
      $get-size: map-get($get-scale, $fontsize);

      $rootsize: nth($sizes, 1);

      $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};

      margin-top: #{$baseline-push}rem;
      // padding-top: #{$baseline-shift}rem;

      // Loop through breakpoints.
      // @for $i from 2 through $points {
      //   $get-scale: map-get($modular-scale, scale-#{$i - 1});
      //   $get-size: map-get($get-scale, $fontsize);
      //   $rootsize: nth($sizes, $i);
      //
      //   $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
      //   $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};
      //
      //   @include breakpoint(nth($points, $i)) {
      //     margin-bottom: #{$baseline-push}rem;
      //     padding-top: #{$baseline-shift}rem;
      //   }
      // }

    } @else {
      @warn "#{$fontsize} is not a valid scale variable";
    }

  } @else {
    @warn "#{$breakpoint} is not valid to use as a breakpoint";
  }
}

// Set fontsize and baseline at once. Mix of fontsize and baseline mixin.
@mixin sassline($fontsize, $font, $lineheight: 2, $below: 2, $breakpoint: 0) {
  $font-value: type-of($fontsize);
  $break-value: type-of($breakpoint);
  $cap-height: map-get($font, cap-height);
  $in-scale: in-modular-scale(scale-0, $fontsize);

  line-height: #{$lineheight}rem;

  @if $break-value == number and $breakpoint <= ($breakpoints-limit - 1) and $breakpoint >= 0 {
    @if $font-value == number {
      $rootsize: nth($sizes, ($breakpoint + 1));
      $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};
      font-size: #{$fontsize / nth($sizes, ($breakpoint + 1))}rem;
      margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;
    } @else if $in-scale == true {
      $get-scale: map-get($modular-scale, scale-#{$breakpoint});
      $get-size: map-get($get-scale, $fontsize);
      $rootsize: nth($sizes, ($breakpoint + 1));
      $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};
      font-size: #{$get-size / nth($sizes, ($breakpoint + 1))}rem;
      margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;
    } @else {
      @warn "#{$fontsize} is not a valid scale variable";
    }
  } @else if $breakpoint == all {
    @if $font-value == number {
      $rootsize: nth($sizes, 1);
      $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};
      font-size: #{$fontsize / nth($sizes, 1)}rem;
      margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;

      @for $i from 2 through $breakpoints-limit {
        $rootsize: nth($sizes, $i);
        $baseline-shift: #{($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001};
        $baseline-push: #{$below - (($fontsize / 2 * (($lineheight * $rootsize / $fontsize) - $cap-height)) / $rootsize + 0.00001)};

        @include breakpoint(nth($points, $i)) {
          font-size: #{$fontsize / nth($sizes, $i)}rem;
          margin-bottom: #{$baseline-push}rem;
          padding-top: #{$baseline-shift}rem;
        }
      }

    } @else if $in-scale == true {
      $get-scale: map-get($modular-scale, scale-0);
      $get-size: map-get($get-scale, $fontsize);
      $rootsize: nth($sizes, 1);
      $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
      $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};
      font-size: #{$get-size / nth($sizes, 1)}rem;
      margin-bottom: #{$baseline-push}rem;
      padding-top: #{$baseline-shift}rem;

      @for $i from 2 through $breakpoints-limit {
        $get-scale: map-get($modular-scale, scale-#{$i - 1});
        $get-size: map-get($get-scale, $fontsize);
        $rootsize: nth($sizes, $i);
        $baseline-shift: #{($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001};
        $baseline-push: #{$below - (($get-size / 2 * (($lineheight * $rootsize / $get-size) - $cap-height)) / $rootsize + 0.00001)};

        @include breakpoint(nth($points, $i)) {
          font-size: #{$get-size / nth($sizes, $i)}rem;
          margin-bottom: #{$baseline-push}rem;
          padding-top: #{$baseline-shift}rem;
        }
      }

    } @else {
      @warn "#{$fontsize} is not a valid scale variable";
    }

  } @else {
    @warn "#{$breakpoint} is not valid to use as a breakpoint";
  }
}
