.form-field--slider {
  padding-top: space(sm);
  padding-bottom: space(sm);
  padding-left: space(md);
  padding-right: space(md);

  .form-field__values {
    @include copy--sm;
    min-height: 1rem;
    padding-bottom: space(md);
    margin-left: space(md) * -1;
    margin-right: space(md) * -1;
  }

  &.has-tooltip {
    padding-top: space(lg);
  }
}

$slider-height: 0.75rem;

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
	touch-action: none;
	user-select: none;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
	transition: left animation(speed) animation(ease), top animation(speed) animation(ease);
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: $slider-height;
}
.noUi-horizontal .noUi-handle {
	width: 2rem;
	height: 2rem;
	left: -1rem;
	top: -0.666rem;
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}

/* Styling;
 */
.noUi-background {
	background: color(gray, light);
}
.noUi-connect {
	background: color(primary);
	// box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
	transition: background animation(speed) animation(ease);
}
.noUi-origin {
	border-radius: $slider-height / 2;
  margin-right: -2px;
}
.noUi-target {
	border-radius: $slider-height / 2;
	// border: 1px solid #D3D3D3;
	// box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-target.noUi-connect {
	// box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	border-radius: 1rem;
	background: color(primary);
	cursor: pointer;
}
.noUi-active {
	box-shadow: 0 0 0.5rem rgba(#000, 0.2);
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}



// Tooltips

.noUi-tooltip {
  @include copy--sm;
  // font-weight: font-weight(paragraph, semi-bold);
	display: block;
	position: absolute;
	padding: 2px;
  background-color: rgba(#fff, 0.5);
	text-align: center;
  // color: color(primary);
  white-space: nowrap;

}

.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
	top: -1.5rem;
  transform: translate(-50%, 0);
  left: 50%;
}
.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
	bottom: -32px;
}
.noUi-vertical .noUi-handle-lower .noUi-tooltip {
	left: 120%;
}
.noUi-vertical .noUi-handle-upper .noUi-tooltip {
	right: 120%;
}
