// Grid system

// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin make-row($gutter: $grid-gutter-width) {
  @include clearfix();

  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
}

@mixin make-col($gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin make-col-span($size, $columns: $grid-columns) {
  width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {
  // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}
