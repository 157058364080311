// Color palette

$color-palette: (
    white: (
        base: #fff
    ),
    black: (
        base: #000,
    ),
    gray: (
        dark: #292929,
        base: #4D4D4D,
        medium: #A0A0A0,
        light: #D9D9D9,
        lighter: #F4F4F4
    ),
    peach: (
        base: #FFD6CD,
        dark: #EDA493
    ),
    red: (
        base: #E83030,
        light: #FB5858,
        dark: #C31212
    ),
    blue: (
        base: #AFE3E9,
        dark: #64BCC6
    ),
    brown: (
        base: #DBBE99,
        dark: #B59A78
    ),
    green: (
        base: #8DCFA5,
        dark: #6BA881
    ),
    purple: (
        base: #4E3E55,
        dark: #2E2135
    )
);

@function palette($color, $tone: base) {
  @if map-has-key($color-palette, $color) {
    @return map-get(map-get($color-palette, $color), $tone);
  }
}
