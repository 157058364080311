@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    @if map-get($breakpoints, $breakpoint) == 0 {
      @for $i from 1 through $columns {
        .col--#{$i} {
          @include make-col-span($i, $columns);
          float: left;
        }
      }

      @each $extra-column in map-keys($extra-columns) {
        $current-extra-columns: $extra-column;
        $curretn-extra-map: map-get($extra-columns, $current-extra-columns);
        @each $extra-column-slice in $curretn-extra-map {
          .col--#{$extra-column-slice}\/#{$current-extra-columns} {
            width: percentage( $extra-column-slice / $current-extra-columns);
            float: left;
          }
        }
      }

      @each $modifier in (pull, push, offset) {
        @for $i from 0 through $columns {
          .col--#{$modifier}-#{$i} {
            @include make-col-modifier($modifier, $i, $columns);
          }
        }
      }

    } @else {
      @include breakpoint($breakpoint) {
        @for $i from 1 through $columns {
          .col--#{$i}#{$at}#{$breakpoint} {
            @include make-col-span($i, $columns);
            float: left;
          }
        }

        @each $extra-column in map-keys($extra-columns) {
          $current-extra-columns: $extra-column;
          $curretn-extra-map: map-get($extra-columns, $current-extra-columns);
          @each $extra-column-slice in $curretn-extra-map {
            .col--#{$extra-column-slice}\/#{$current-extra-columns}#{$at}#{$breakpoint} {
              width: percentage( $extra-column-slice / $current-extra-columns);
              float: left;
            }
          }
        }

        @each $modifier in (pull, push, offset) {
          @for $i from 0 through $columns {
            .col--#{$modifier}-#{$i}#{$at}#{$breakpoint} {
              @include make-col-modifier($modifier, $i, $columns);
            }
          }
        }
      }
    }

  }
}
