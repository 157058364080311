// Select

@mixin dropdown {
  position: relative;
  appearance: none;

  height: 50px;
  clear: right;
  padding-left: space(sm);
  padding-right: space(lg);

  border: $form-border-width;
  border-style: solid;
  border-color: $form-border-color;

  color: palette(gray, base);

  // border-bottom: 1px solid $color-green;
  &:hover .dropdown__value {
    //border-color: $input-border-hover-color;
  }

  .block--green & {
    color: #fff;
    border-color: #fff;
  }
}

.dropdown, select {
  @include dropdown;
  option {
    padding: space(sm);
    &:hover {
      background: palette(green);
    }
    &.selected {
      background: palette(green);
    }
  }
}

select {
  //background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
  background: url('../images/icons/icon-caret-down-green.png') no-repeat right #fff;
  background-size: 1rem;
  background-position-x: 90%;
}

.dropdown__value.dropdown__value  {
  padding-right: 2rem;
}

.dropdown--inline {
  display: inline-block;
  padding-right: 2rem;
}

.dropdown__icon-btn {
  width: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: palette(green);

  .dropdown__icon {
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .caret {
    color: #fff;
  }
}

.dropdown__icon {
  position: absolute;
  right: 0.5rem;
  transform: translate(0, -50%);
  top: 50%;

  .form-field--white & {
    color: palette(green);
  }

  .form-field--white.form-field--ghost & {
    color: palette(green);
  }

  .form-field--lg & {
    right: 1.5rem;
  }
}

.form-input--lg + .dropdown__icon {
  right: 0.75rem;
}

.dropdown__select {
  cursor: pointer;
  height: 100%;
  left: 1rem;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.dropdown__select:focus + .form-control {
  @include tab-focus-none();
  // border-color: $input-border-focus-color
}