// Grid

$base-unit-size: 16;
$base-font-size: 16;
$base-column-width: 300;

$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         3rem !default;
$grid-gutter-width-half:    ($grid-gutter-width / 2);

//

$extra-columns: (
  8: (1, 3),
  5: (1)
);

$column-gutter-widths: (($base-font-size / 2) * ($grid-columns + 2));

$container-max-widths: (
  xxs: 0,
  xs: 0,
  sm: 0,
  md: ($base-column-width * 2) + (($base-font-size / 2) * 6),
  lg: ($base-column-width * 3) + (($base-font-size / 2) * 10),
  xl: ($base-column-width * 4) + (($base-font-size / 2) * 14)
);

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
