@mixin alert {
  @include reset-ul;
  padding: space(sm);
  margin-bottom: space(sm);
  margin-top: space(sm);
  border: $form-border-width solid color(muted);
  a {
	font-weight: font-weight(paragraph, bold);
	&:hover {
	  text-decoration: underline;
	}
  }
}

@mixin alert-info {
  border-color: color(blue);
  color: color(blue, dark);
}

@mixin alert-white {
  border-color: color(white);
  color: color(white);
}

@mixin alert-error {
  border-color: color(red);
  color: color(red);
}

@mixin alert-green {
  border-color: color(green);
  color: color(green);
}