// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-fluid($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

@mixin img-fluid--center() {
  margin-left: auto;
  margin-right: auto;
}
