/* http://css-tricks.com/responsive-data-tables/ */
@include breakpoint(0, md) {

  
  /*
  .woocommerce-cart, .woocommerce-checkout {
	.wizard {
	  display: none;
	}
  }
  */
  
  .woocommerce-cart .cart_totals {
	h2 {
	  @include copy--h3;
	}
  }
  table.cart {

	/* Force table to not be like tables anymore */
	&, thead, tbody, th, td, tr {
	  display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
	  @include sr-only;
	}

	tr.cart_item {
	  margin-bottom: space(md);
	  td {
		text-align: right;
		padding: space(xs) space(md) !important;

		&.product-price, &.product-quantity, &.product-subtotal {
		  position: relative;
		  padding-left: 30% !important;
		  clear: both;
		  &:before {
			text-align: left;
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: space(xs);
			left: space(sm);
			width: 25%;
			padding-right: space(sm);
			white-space: nowrap;
		  }
		}
		&.product-quantity {
		  .quantity input {
			padding: space(xs) / 2;
			width: 40px;
			text-align: center;
		  }
		}
		&.product-thumbnail {
		  display: none;
		}
		&.product-remove {
		  position: absolute;
		  .remove::after {
			width: 20px;
			height: 20px;
		  }
		}
		&.product-name {
		  text-align: right;
		  margin-left: 30px;
		  a {
			font-weight: font-weight(paragraph, bold);
			text-decoration: underline;
		  }
		  dt, dd {
			float: none;
		  }
		  dl {
			margin-bottom: 0;
		  }
		}
	  }
	}

	td.actions {
	  .coupon, input[name="update_cart"] {
		float: none;
	  }
	  #coupon_code, input[name="apply_coupon"] {
		margin-bottom: space(xs);
	  }
	}

	/*
	Label the data
	*/

	td.product-price:before {
	  content: "Hinta";
	}

	td.product-quantity:before {
	  content: "Määrä";
	}

	td.product-subtotal:before {
	  content: "Yhteensä";
	}
  }
}

/* END Make the cart table responsive */