// WebKit-style focus

@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Disable outline

@mixin tab-focus-none() {
  // Default
  outline: 0;
}
