// White

.u-color-white {
  color: palette(white);
}

.u-color-white-bg {
  background-color: palette(white);
}

// Black

.u-color-black {
  color: palette(black);
}

.u-color-black-bg {
  background-color: palette(black);
}


// Gray

.u-color-gray {
  color: palette(gray);
}

.u-color-gray.light {
  color: palette(gray, light);
}

.u-color-gray.lighter {
  color: palette(gray, lighter);
}

.u-color-gray.dark {
  color: palette(gray, dark);
}

.u-color-gray-bg {
  background-color: palette(gray);
}

.u-color-gray-bg.light {
  background-color: palette(gray, light);
}

.u-color-gray-bg.lighter {
  background-color: palette(gray, lighter);
}

.u-color-gray-bg.dark {
  background-color: palette(gray, dark);
}

// Red

.u-color-peach {
  color: palette(peach);
}

.u-color-peach.dark {
  color: palette(peach, dark);
}

.u-color-peach-bg {
  background-color: palette(peach);
}

.u-color-peach-bg.dark {
  background-color: palette(peach, dark);
}


// Blue

.u-color-blue {
  color: palette(blue);
}

.u-color-blue.dark {
  color: palette(blue, dark);
}

.u-color-blue-bg {
  background-color: palette(blue);
}

.u-color-blue-bg.dark {
  background-color: palette(blue, dark);
}


// Brown

.u-color-brown {
  color: palette(brown);
}

.u-color-brown.dark {
  color: palette(brown, dark);
}

.u-color-brown-bg {
  background-color: palette(brown);
}

.u-color-brown-bg.dark {
  background-color: palette(brown, dark);
}

// Green

.u-color-green {
  color: palette(green);
}

.u-color-green.dark {
  color: palette(green, dark);
}

.u-color-green-bg {
  background-color: palette(green);
}

.u-color-green-bg.dark {
  background-color: palette(green, dark);
}




// Purple

.u-color-purple {
  color: palette(purple);
}

.u-color-purple.dark {
  color: palette(purple, dark);
}

.u-color-purple-bg {
  background-color: palette(purple);
}

.u-color-purple-bg.dark {
  background-color: palette(purple, dark);
}

// Red

.u-color-red {
  color: palette(red);
}

.u-color-red-bg {
  background-color: palette(red);
}