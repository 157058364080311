.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-muted {
  color: color(muted);
}

.u-text-secondary {
  color: color(secondary);
}

.u-text-highlight {
  color: color(highlight);
}

.u-text-alert {
  color: color(alert);
}

@each $breakpoint in map-keys($breakpoints) {
  @include breakpoint($breakpoint) {
    .u-text-left#{$at}#{$breakpoint} {
      text-align: left !important;
    }

    .u-text-right#{$at}#{$breakpoint} {
      text-align: right !important;
    }

    .u-text-center#{$at}#{$breakpoint} {
      text-align: center !important;
    }
  }
  @include breakpoint(0, $breakpoint) {
    .u-text-left#{$at}#{$breakpoint}-down {
      text-align: left !important;
    }

    .u-text-right#{$at}#{$breakpoint}-down {
      text-align: right !important;
    }

    .u-text-center#{$at}#{$breakpoint}-down {
      text-align: center !important;
    }
  }
}
