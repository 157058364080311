html.has-cookie-bar.cookie-bar-top-bar {
  margin-top: 30px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}
#catapult-cookie-bar {
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  direction: ltr;
  padding: 0 10px;
  z-index: 99999;
  overflow: hidden;
  text-align:left;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;

  background-color: color(blue);
  color: color(blue, dark);

  a {
	font-weight: font-weight(paragraph, bold);
  }
}
#catapult-cookie-bar.rounded-corners {
  border-radius: 3px;
}
#catapult-cookie-bar.drop-shadow {
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.4);
}
.cookie-bar-block #catapult-cookie-bar {
  max-height: 999px;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.has-cookie-bar #catapult-cookie-bar {
  opacity: 1;
  max-height: 999px;
  min-height: 30px;
  padding: 5px 20px 5px;
}
.cookie-bar-block #catapult-cookie-bar span {
  display: block;
  margin: 1em 0;
}
.cookie-bar-bar #catapult-cookie-bar.float-accept span.ctcc-right-side {
  float: right;
}
button#catapultCookie {
  cursor: pointer;
  margin:0 20px 0 0;
}
.cookie-bar-bar button#catapultCookie {
  margin:0 0 0 20px;
}
.cookie-bar-bar .ctcc-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
}
.cookie-bar-bar .float-accept .ctcc-inner {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.ctcc-left-side {
  margin-right: 50px;
}
.ctcc-right-side {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.cookie-bar-block #catapult-cookie-bar h3 {
  margin: 0.5em 0 0;
}
.use_x_close .x_close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-family: sans-serif;
  -webkit-transition-duration:0.5s;
  transition-duration:0.5s;
}
.cookie-bar-bar .use_x_close .x_close {
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}


.x_close {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0px,0px,0px);
  width: 14px;
  height: 30px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.x_close span {
  display: block;
  position: absolute;
  height: 2px;
  width: 14px;
  background: color(blue, dark);
  border-radius: 6px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  transition-delay: .14s;
  -webkit-transition-delay: .14s;
  -moz-transition-delay: .14s;
}
.x_close span:nth-child(1),
.x_close span:nth-child(2) {
  top: 14px;
}
.x_close span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.x_close span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.x_close:hover span:nth-child(1) {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.x_close:hover span:nth-child(2) {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}