//
// Links
//

a {
  color: inherit;
  text-decoration: $link-decoration;

  @include hover-focus {
    // color: inherit;
    text-decoration: $link-decoration-hover;
  }

  &:focus {
    @include tab-focus-none();
  }
}

footer a {
  text-decoration: none;
  @include hover-focus {
    text-decoration: underline;
  }
}