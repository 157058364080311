// Klarna-kassa
.page-id-2864, .page-id-2878 {
  .main-content {
	h2 {
	  text-align: center;
	  padding-top: space(lg);
	  padding-bottom: space(sm);
	}
	p {
	  padding-top: space(md);
	  text-align: center;
	}
  }
}