.icon {
  position: relative;
  display: inline-block;
  line-height: 1;

  width: 2rem;
  height: 2rem;
  vertical-align: -0.25rem;
  transition: transform 0.3s $animation-default;

  > svg {
	position: relative;
	width: 100%;
	height: auto;
	display: block;
	fill: currentColor;
	transition: fill 0.3s $animation-default;
  }
}

.svg-inline > svg {
  max-width: 100%;
  height: auto;
}

.svg--white > svg {
  fill: palette(white);
}

// Sizes

.icon--xs {
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125rem;
}

.icon--sm {
  width: 1.5rem;
  height: 1.5rem;
}

.icon--md {
  width: 2rem;
  height: 2rem;
  // vertical-align: -0.5rem; //TODO: inspect if needed
  &.icon--circle {
	width: 2.5rem;
	height: 2.5rem;
  }
}

.icon--lg {
  width: 3rem;
  height: 3rem;
  vertical-align: -1rem;
}

.icon--xl {
  max-width: 100%;
  width: 4rem;
  height: 4rem;
}

// Strokes

.icon--stroke-1 {
  stroke-width: 1;
}

.icon--stroke-2 {
  stroke-width: 2;
}

.icon--stroke-3 {
  stroke-width: 3;
}

// Colors

.icon--green {
  color: palette(green);
  &.icon--stroke {
	stroke: palette(green);
	&:hover {
	  stroke: palette(green, dark);
	}
  }
  &:hover {
	color: palette(green, dark);
  }
}

.icon--white {
  color: palette(white);
  &.icon--stroke {
	stroke: palette(white);
	&:hover {
	  stroke: palette(gray, lighter);
	}
  }
  &:hover {
	color: palette(gray, lighter);
  }
}

.icon--black {
  color: palette(black);
  &.icon--stroke {
	stroke: palette(black);
	&:hover {
	  stroke: palette(gray, dark);
	}
  }
  &:hover {
	color: palette(gray, dark);
  }
}

.icon--gray-medium {
  color: palette(gray, medium);
  &.icon--stroke {
	stroke: palette(gray, medium);
	&:hover {
	  stroke: palette(gray, dark);
	}
  }
  &:hover {
	color: palette(gray, dark);
  }
}

.icon--gray-light {
  color: palette(gray, light);
  &.icon--stroke {
	stroke: palette(gray, light);
	&:hover {
	  stroke: palette(gray, dark);
	}
  }
  &:hover {
	color: palette(gray, dark);
  }
}

.icon--gray {
  color: palette(gray);
  &.icon--stroke {
	stroke: palette(gray);
	&:hover {
	  stroke: palette(gray, dark);
	}
  }
  &:hover {
	color: palette(gray, dark);
  }
}

.icon--dark {
  color: palette(gray, dark);
  &.icon--stroke {
	stroke: palette(gray, dark);
	&:hover {
	  stroke: palette(black);
	}
  }
  &:hover {
	color: palette(black);
  }
}

.icon--red {
  color: palette(red);
  &.icon--stroke {
	stroke: palette(red);
	&:hover {
	  stroke: palette(red, dark);
	}
  }
  &:hover {
	color: palette(red, dark);
  }
}

.icon--primary {
  color: color(primary);
  &.icon--stroke {
	stroke: color(primary);
	&:hover {
	  stroke: color(secondary);
	}
  }
  &:hover {
	color: color(secondary);
  }
}

.icon--secondary {
  color: color(secondary);
  &.icon--stroke {
	stroke: color(secondary);
	&:hover {
	  stroke: color(primary);
	}
  }
  &:hover {
	color: color(primary);
  }
}

.icon--brand {
  color: color(brand);
  &.icon--stroke {
	stroke: color(brand);
	&:hover {
	  stroke: darken(color(brand), 10%);
	}
  }
  &:hover {
	color: darken(color(brand), 10%);
  }
}

.icon--muted, .icon--disabled {
  color: color(muted);
  &.icon--stroke {
	stroke: color(muted);
	&:hover {
	  stroke: darken(color(muted), 10%);
	}
  }
  &:hover {
	color: darken(color(muted), 10%);
  }
}

// Rotate

.icon--left {
  //transform: rotate(0deg);
}

.icon--right {
  transform: rotate(180deg);
}

.icon--up {
  transform: rotate(90deg);
}

.icon--down {
  transform: rotate(-90deg);
}

// Special

.icon--arrow-right {
  transform: rotate(180deg);
}

.icon + span {
  margin-left: 0.5rem;
}

.section-heading .icon {
  vertical-align: -0.125rem;
}


// Caret

@mixin sort {
  display: inline-block;
  width: 0.8rem;
  height: 0.85rem;
  position: relative;
  vertical-align: -0.1rem;

  &::before,
  &::after {
	position: absolute;
	display: inline-block;
	width: 0;
	height: 0;
	left: 0;
	margin-right: .1rem;
	margin-left: .1rem;
	vertical-align: middle;
	content: '';
	border-right: 0.4rem solid transparent;
	border-left: 0.4rem solid transparent;
  }
  // Up
  &::before {
	top: 0;
	border-bottom: 0.4rem solid currentColor;
  }
  // Down
  &::after {
	border-top: 0.4rem solid currentColor;
	bottom: 0;
  }
}

@mixin caret--lg {
  width: 1.2rem;
  &::before,
  &::after {
	border-right: 0.6rem solid transparent;
	border-left: 0.6rem solid transparent;
  }
  // Up
  &::before {
	border-bottom: 0.6rem solid currentColor;
  }
  // Down
  &::after {
	border-top: 0.6rem solid currentColor;
  }
}

.sort {
  @include sort;
}

@mixin sort--up {
  &::before {
	top: 0.2rem;
  }
  &::after {
	display: none;
  }
}

.sort--up {
  @include sort--up;
}

@mixin sort--down {
  &::after {
	bottom: 0.2rem;
  }
  &::before {
	display: none;
  }
}

.sort--down {
  @include sort--down;
}

.caret {
  @include sort;
  @include sort--down;
}

.caret--lg {
  @include caret--lg;
}
