.facetwp-template {
  padding-left: space(md);
  padding-right: space(md);
}

ul.products {
  @include reset-ul;
  @include clearfix;
  padding-top: 2rem;
  clear: both;
  h3 {
	@include copy--base;
	color: color(muted);
	text-transform: capitalize;
  }
  h2, h4 {
	display: inline-block;
	@include copy--base;
	line-height: 1rem;
  }
  h2 {
	text-transform: uppercase;
	span {
	  text-transform: lowercase;
	  font-weight: font-weight(normal);
	}
  }

  li.product {
	> .product-wrapper {
	  position: relative;
	  float: left;

	  width: 100%;
	  margin-top: 1rem;
	  margin-bottom: 2rem;

	  padding-left: space(md);
	  padding-right: space(md);

	  //> a {
		//position: relative;
		//display: block;
		//padding-bottom: space(sm);
		//border: 1px solid color(gray, light);
	  //}

	  .product-wrapper-inner {
		position: relative;
		display: block;
		padding-bottom: space(sm);
		border: 1px solid color(gray, light);

		transition: all .1s ease-in-out;
		&:hover {
		  transform: scale(1.05, 1.05);
		}
	  }

	  a > h3 {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0.5rem;
		padding-left: 1rem;
	  }

	  .product-name {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-left: 1rem;
		padding-right: 3rem;

		height: 2.5rem;
		h2 {
		  margin-top: 0;
		  margin-bottom: 0;
		}
	  }

	  .price {
		clear: both;
		display: block;
		padding-left: 1rem;
		del {
		  //color: color(muted);
		}
		ins {
		  text-decoration: none;
		  color: color(red);
		}
	  }

	  $tag-base-size: 24px;
	  .onsale {

		position: absolute;
		height: $tag-base-size;
		font-size: $tag-base-size / 2;
		line-height: $tag-base-size;
		top: ($tag-base-size / 2) * -1;
		right: ($tag-base-size / 2) * -1;
		background-color: color(brand);
		color: color(white);
		text-transform: uppercase;

		padding-left: $tag-base-size;
		padding-right: $tag-base-size;

		vertical-align: text-top;

		&::after{
		  content: '';
		  position: absolute;
		  //left: 42%;
		  right: 0;
		  top: 99%;
		  width: 0;
		  height: 0;

		  //border-left: 20px solid transparent;
		  border-right: $tag-base-size / 2 solid transparent;
		  border-top: $tag-base-size / 2 solid darken(color(brand), 10%);

		  //border-style: solid;
		  //border-width: $tag-base-size $tag-base-size 0 0;
		  //border-color: #007bff transparent transparent transparent;
		  clear: both;
		}
	  }

	  .woo_tag {
		padding-top: 0;
		margin-top: 0.5rem;
		margin-left: 1rem;
	  }

	  @include breakpoint(md, lg) {
		width: calc((100% / 2));
		&:nth-of-type(2n+3) {
		  clear: left;
		}
	  }
	  @include breakpoint(lg, xl) {
		width: calc(100% / 3);
		&:nth-of-type(3n+4) {
		  clear: left;
		}
	  }
	  @include breakpoint(xl) {
		width: calc(100% / 4);
		&:nth-of-type(4n+5) {
		  clear: left;
		}
	  }
	  @include breakpoint(1700) {
		width: calc(100% / 5);
		&:nth-of-type(5n+6) {
		  clear: left;
		}
	  }
	  @include breakpoint(2200) {
		width: calc(100% / 6);
		&:nth-of-type(6n+7) {
		  clear: left;
		}
	  }
	  @include breakpoint(2900) {
		width: calc(100% / 7);
		&:nth-of-type(7n+8) {
		  clear: left;
		}
	  }

	  .yith-wcwl-add-to-wishlist, .yith-wcwl-remove-from-wishlist {
		display: block;
		position: absolute;
		bottom: 90px;

		z-index: 2;
		//margin-left: -1 * space(md);
		//margin-right: -1 * space(md);
		//margin-right: 3px;
	  }

	  .yith-wcwl-add-to-wishlist {
		right: -5px;
	  }

	  .yith-wcwl-remove-from-wishlist {
		right: 20px;
	  }

	  .yith-wcwl-wishlistaddedbrowse {
		padding-right: 20px;
	  }

	  //.sale, .wishlist {
	  //  margin-top: -2.5rem;
	  //}
	  .attachment-shop_catalog {
		width: 100%;
		max-width: 100%;
		height: auto;
	  }
	}
  }
  .add_to_cart_button {
	display: block;
  }
}

nav.woocommerce-pagination, nav.woocommerce-pagination ~ .woocommerce-result-count, nav.woocommerce-pagination ~ .woocommerce-ordering {
  display: none;
}

.fwp-load-more {
  width: 100%;
  text-align: center;
  margin-top: space(lg);
}

.woocommerce-result-count {
  float: left;
  clear: both;
  margin-left: $grid-gutter-width-half;
  padding-top: space(lg);
  @include breakpoint(0, md) {
	padding-top: space(md);
	float: none;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
  }
}

.woocommerce-ordering {
  float: right;
  margin-right: $grid-gutter-width-half;
  margin-top: 0;
  padding-top: space(lg);
  @include breakpoint(0, md) {
	padding-top: space(md);
  }
}
