// Page
$body-color: color(black);
$body-bg: color(white);

$border-height-default: 4px; // 0.4rem;
$border-height-thin: 2px; // 0.15rem;

// Links
$link-color: color(body, color);
$link-color-hover: color(body, color);
$link-decoration: none;
$link-decoration-hover: none;

// Typo
$heading-color: color(body, color);

// Site header
$site-header-bg: color(brand);
$primary-nav-breakpoint: 1100;
$secondary-nav-breakpoint: 1860;
$aside-nav-breakpoint: 1330;
$mobile-header-breakpoint: $primary-nav-breakpoint;

// Site footer

// Buttons

// Cards

// Forms
$form-border-width: 0.1rem;
$form-border-color: color(gray, light);
$form-label-color: color(gray, dark);

  // Ticks
  $tick-border-width: 0.125rem;
  $tick-border-color: color(secondary, dark);
  $tick-bg-checked: color(secondary);

    // Checkbox
    $checkbox-bg-checked: color(gray, light);

// Tables
