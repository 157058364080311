@mixin btn {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: $form-border-width solid transparent;
  background-color: color(green);
  color: #fff;
  padding: space(sm) space(md);

  font-weight: bold;

  font-size: 18px;

  transition:
      color animation(speed) animation(ease),
      border animation(speed) animation(ease),
      background animation(speed) animation(ease)
;

  > span:not(.icon) {
    vertical-align: middle;
  }

  @include hover-focus {
    @include tab-focus-none;
    text-decoration: none;
    background-color: color(green, dark);
  }

  &.disabled,
  &.is-disabled,
  &.btn--disabled,
  &.slick-disabled {
    background-color: color(muted) !important;
    cursor: default;
  }

  .icon:not(.icon--iconbutton) {
    margin-right: space(xs);
  }
}

.btn, button, input[type="submit"], .button {
  @include btn;
}

// Sizes

@mixin btn--xs {
  padding: space(xs) / 2 space(xs) / 2;
}

.btn--xs {
  @include btn--xs;
}

@mixin btn--sm {
  padding: space(sm) space(sm);
}

.btn--sm {
  @include btn--sm;
}

@mixin btn--lg {
  padding: 20px space(lg);
  font-size: 22px;
}

.btn--lg {
  @include btn--lg;
}

@mixin btn--block {
  width: 100%;
}

.btn--block {
  @include btn--block;
}

@each $breakpoint in map-keys($breakpoints) {
  @include breakpoint(0, $breakpoint) {
    .btn--block#{$at}#{$breakpoint}-down {
      width: 100%;
    }
  }
}

// Color

@mixin btn--blank {
  border: 0;
  background: transparent;
  padding: 0;
  color: color(primary);
  line-height: 1.5;
  @include hover-focus {
    background: transparent;
    color: color(secondary);
  }
}

.btn--blank {
  @include btn--blank;
}

@mixin btn--primary {
  background-color: color(primary);
  @include hover-focus {
    background-color: color(primary, dark);
  }
}

.btn--primary {
  @include btn--primary;
}

@mixin btn--secondary {
  background-color: color(secondary);
  @include hover-focus {
    background-color: color(secondary, dark);
  }
}

.btn--secondary {
  @include btn--secondary;
}

@mixin btn--highlight {
  background-color: color(highlight);
  @include hover-focus {
    background-color: color(highlight, dark);
  }
}

.btn--highlight {
  @include btn--highlight;
}

@mixin btn--alert {
  background-color: color(alert);
  @include hover-focus {
    background-color: color(alert, dark);
  }
}

.btn--alert {
  @include btn--alert;
}

@mixin btn--white {
  background-color: #fff;
  color: color(body, color);
  @include hover-focus {
    background-color: color(body, color);
    color: #fff;
  }
}

.btn--white {
  @include btn--white;
}


@mixin btn--hover-primary {
  @include hover-focus {
    background-color: color(primary) !important;
    color: color(white);
  }
}

.btn--hover-primary {
  @include btn--hover-primary;

}

// Photo background

@mixin btn--photo-bg {
  background-color: #fff;
  color: color(brand);
  @include hover-focus {
    background-color: color(brand);
    color: #fff;
  }
}

.btn--photo-bg {
  @include btn--photo-bg;
}

// Ghost

@mixin btn--ghost {
  color: color(primary);
  background-color: transparent;
  border-color: currentColor;

  .t-red & {
    color: #fff;
    border-color: color(white);
    .icon {
      transition: transform animation(speed) animation(ease);
    }
    @include hover-focus {
      color: #fff;
      // .icon {
      //   transform: translate3d(0.5rem, 0, 0);
      // }
    }
  }

  &.btn--secondary {
    color: color(secondary);
  }

  &.btn--photo-bg {
    color: color(white);
    @include hover-focus {
      background-color: color(brand);
    }
  }

  &.btn--white {
    color: color(white);
    @include hover-focus {
      color: color(gray, dark);
      background-color: color(white);
      border-color: color(white);
    }
  }

  @include hover-focus {
    color: color(primary, bright);
    background-color: transparent;
    border-color: currentColor;
  }
}

.btn--ghost {
  @include btn--ghost;
}

// Toggle

@mixin toggle-btn {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background: transparent;
  color: color(highlight);
  font-weight: font-weight(heading, semi-bold);

  transition: color animation(speed, fast) animation(ease);

  @include hover-focus {
    @include tab-focus-none;
    color: color(secondary);
  }

  .caret {
    transform-origin: center;
    transition: transform animation(speed) animation(ease);
  }

  &.is-open {
    .caret {
      transform: rotate(180deg);
    }
  }
}

.toggle-btn {
  @include toggle-btn;
}
