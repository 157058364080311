@mixin form-group--inline {

  .form-group__item {
    display: inline-block;
    margin-right: space(md);
  }

}

.form-group--inline {
  @include form-group--inline;
}

@each $breakpoint in map-keys($breakpoints) {
  .form-group--inline#{$at}#{$breakpoint} {
    .form-group__item {
      @include breakpoint(0, $breakpoint) {
        & + .form-group__item {
          margin-top: space(sm);
        }
      }
      @include breakpoint($breakpoint) {
        display: inline-block;
        margin-right: space(md);
      }
    }
  }
}
