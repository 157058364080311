body.woocommerce-cart {
  .page-header {
	text-align: center;
  }

  .main-content > h3 {
	text-align: center;
  }

  table.cart, .cart_totals, .cart-collaterals, .woocommerce-info {
	width: 90%;
	max-width: 800px;
	margin: auto;
  }

  .cart_totals {
	margin-top: space(lg);
  }

  thead {
	text-transform: uppercase;
	color: color(muted);
	transform: translateY(10px);
  }

  table.cart {
	border-spacing: 0 10px;
	border-collapse: separate;
	tbody > tr {
	  background-color: color(gray, lighter);
	  td {
		padding: space(md);
	  }
	}
  }
  .product-thumbnail img {
	width: 100px;
	height: auto;
	margin-top: space(sm);
	margin-bottom: space(sm);
  }
  .variation-color {
	text-transform: capitalize;
  }
  dd, dt {
	display: inline-block;
  }
  .coupon {
	float: left;
	//max-width: 400px;
	label {
	  display: none;

	}
	.input-text {
	  @include form-field__input--sm;
	  @include form-field__inline;
	  //display: inline-block;
	  width: 150px;
	  margin-right: space(sm);
	}
	.button {
	  @include btn;
	  //display: inline-block;
	}
  }
  input[name="update_cart"] {
	float: right;
	@include btn;

  }
  .cart_totals {
	h2 {
	  float: left;
	}
	table {
	  float: right;

	  th {
		padding-right: space(md);
		width: 120px;
	  }
	  td {
		width: 250px;
	  }

	}
	.shipping-calculator-form {
	  select, input, option {
		width: 225px;
	  }
	  select, input {
		margin-bottom: space(sm);
	  }
	}
	.shipping {
	  ul {
		@include reset-ul;
	  }
	  li {
		margin-bottom: space(xs) / 2;
	  }
	  th {
		vertical-align: top;
	  }
	}
	.shipping-calculator-button {
	  @include btn;
	  @include btn--secondary;
	  margin-bottom: space(sm);
	}
	.wc-proceed-to-checkout {
	  clear: both;
	  .button {
		margin-top: space(lg);
		@include btn--block;
	  }
	}
	.order-total {
	  @include copy--h3;
	  small {
		font-size: 1rem;
		clear: both;
		display: block;
	  }
	}

  }
  .quantity input {
	width: 60px;
  }
  .cart-collaterals {
	clear: both;
	margin-top: space(xl);
	li.product {

	}
  }

  .remove {
	//display: none;
	font-size: 0;
	&::after {
	  content: "";
	  width: 30px;
	  height: 30px;
	  background: url("/wp-content/themes/dfs16/dist/images/icons/icon-trashcan.png") 0 0 no-repeat;
	  background-size: contain;
	  top: 10px;
	  right: 5px;
	  display: inline-block;
	}
  }


  .woocommerce-message {
	margin-left: auto;
	margin-right: auto;
	padding: space(sm);
	border: $form-border-width solid color(muted);
	border-color: color(blue);
	color: color(blue);
	width: 50%;
	a {
	  font-weight: font-weight(paragraph, bold);
	}
  }


  @include breakpoint(0, 650) {
	input[name="update_cart"] {
	  margin-top: space(sm) !important;
	  clear: both;
	  display: block;
	  float: left;
	}
  }

  @include breakpoint(0, md) {
	.cart_totals .shop_table_responsive {
	  h2 {
		@include copy--h3;
	  }
	  float: none !important;
	  th, td {
		display: block;
		width: 100%;
	  }
	}
  }
  @include breakpoint(0, lg) {
	.actions input, .shipping-calculator-button {
	  padding: space(xs) / 2 space(xs) / 2 !important;
	  font-size: 1rem !important;
	}
  }
}