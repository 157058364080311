@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin form-field__label {
  z-index: 1;
  position: absolute;
  top: $grid-gutter-width / 2.5;
  left: $grid-gutter-width-half;

  padding: $form-border-width;

  pointer-events: none;

  color: $form-label-color;

  transition: opacity animation(speed) animation(ease);
}

@mixin form-field__placeholder {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: $grid-gutter-width-half;
  color: color(body);
  font-weight: bold;
  pointer-events: none;

  transition: opacity animation(speed) animation(ease);
}


@mixin form-field__input {
  //display: block;
  //width: 100%;

  border: $form-border-width;
  border-style: solid;
  border-color: $form-border-color;

  padding-left: space(sm);
  padding-left: space(sm);
  padding-bottom: space(sm);
  padding-top: space(sm);

  color: color(body);
  font-weight: bold;
  background-color: #fff;

  vertical-align: middle;

  transition:
      border animation(speed) animation(ease),
      color animation(speed) animation(ease)
;

  @include hover-focus {
    border-color: color(green);
  }

  &:focus {
    @include tab-focus-none();
    color: color(gray, dark);
  }
}

// State

@mixin disabled {
  color: color(gray);
}

@mixin has-error {
  //color: color(alert) !important;
}

.woocommerce-invalid input, input.error {
  color: color(alert) !important;
  border-color: color(alert) !important;
}

// Sizes
@mixin form-field__input--sm {
  padding: space(sm);
}

@mixin form-field--sm {
  .form-field__input {
    @include form-field__input--sm;
  }
  &.form-field--pl {
    .form-field__label {
      display: none;
    }
  }
}

@mixin form-field__input--md {
  padding: 0.9rem;
}

@mixin form-field--md {
  .form-field__input {
    @include form-field__input--md;
  }
  &.form-field--pl {
    .form-field__label {
      display: none;
    }
  }
}

@mixin form-field__input--xs {
  padding: 0;
  letter-spacing: 0.05rem;
}

@mixin form-field--xs {
  .form-field__input,
  .form-field__typeahead .form-field__input {
    @include form-field__input--xs;
  }

  &.form-field--embed, &.form-field--embed-highlight {
    .form-field__label {
      @include font-size(paragraph, xs);
      @include baseline(paragraph, xs, $type-body, 1);
      line-height: 1;
      display: block;
    }
    .form-field__input {
      line-height: 1;
    }
  }
}

// Variations

@mixin form-field--embed {
  .form-field__label {
    color: inherit;
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
  }
  .form-field__input {
    color: inherit;
    border: none;
    background-color: transparent;
  }
}

@mixin form-field--embed-highlight {
  .form-field__label {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
  }
  .form-field__input {
    border: none;
    background-color: transparent;
  }
}

@mixin form-field--inline {
  display: inline-block;
  .form-field__input {
    @include font-size(paragraph, xl);
    line-height: 1;
  }
  .form-field__label {
    top: space(sm) * 1.25;
    left: 2rem;
  }
  &.form-field--xs {
    .form-field__input {
      padding-top: space(sm);
      padding-bottom: space(sm);
    }
  }
}


@mixin form-field--pl {
  label:not(.checkbox) {
    position: absolute;
    top: 0.1rem;
    left: 1rem;
    color: color(body);
    @include font-size(paragraph, xs);

    opacity: 0;
    transform: translate(0, 1rem);
    transition-duration: animation(speed);
    transition-timing-function: animation(ease);
    transition-property: opacity, transform;
  }

  label:not(.checkbox).error, label:not(.checkbox).error-class {
    top: 2.4rem;
    transform: translate(0, -1rem);
    margin-left: 0;
  }


  input:not([type='checkbox']) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &.is-active {
    label:not(.checkbox) {
      opacity: 1;
      transform: translate(0, 0);
    }
    input:not([type='checkbox']) {
      //padding-top: 1.5rem;
      //padding-bottom: 0.5rem;
    }
    input:not([type='checkbox']):focus {
      @include placeholder {
        color: transparent;
      }
    }
    //.form-field__placeholder {
    //  opacity: 0;
    //}
  }
}

.form-field--pl {
  @include form-field--pl;
}

@mixin form-field--default {

  .form-field__label {
    @include form-field__label;
  }

  .form-field__placeholder {
    @include form-field__placeholder;
  }

  .form-field__label ~ .form-field__input,
  .form-field__label ~ .form-field__dropdown .form-field__input,
  .form-field__label ~ .form-field__typeahead .form-field__input {
    padding-top: space(lg);
  }

  .form-field__input {
    @include form-field__input;
  }

  // State

  &.is-disabled .form-field__input,
  .form-field__input:disabled {
    @include disabled;
  }

  &.has-error .form-field__input {
    @include has-error;
  }

  &.error {
    @include has-error;
  }

  // Sizes
  &.form-field--sm {
    @include form-field--sm;
  }

  &.form-field--md {
    @include form-field--md;
  }

  &.form-field--xs {
    @include form-field--xs;
  }

  // Variations

  &.form-field--embed {
    @include form-field--embed;
  }

  &.form-field--embed-highlight {
    @include form-field--embed-highlight;
  }

  &.form-field--inline {
    @include form-field--inline;
  }


  &.form-field--pl {
    @include form-field--pl;
  }
}

.form-field--default {
  @include form-field--default;
}
