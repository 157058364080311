@mixin make-stacks($name:'u-stack', $prop:'margin') {
  $y-spaces: map-get($stack-map, y);

  @each $breakpoint in map-keys($breakpoints) {
    @if map-get($breakpoints, $breakpoint) == 0 {
      .#{$name} {
        * + & {
          #{$prop}-top: stack(md);
        }
      }

      @each $space in map-keys($y-spaces) {
        .#{$name}--#{$space} {
          * + & {
            #{$prop}-top: stack($space);
          }
        }
      }

    } @else {
      @include breakpoint(0, $breakpoint) {
        .#{$name}#{$at}#{$breakpoint}-down {
          * + & {
            #{$prop}-top: stack(md);
          }
        }

        @each $space in map-keys($y-spaces) {
          .#{$name}--#{$space}#{$at}#{$breakpoint}-down {
            * + & {
              #{$prop}-top: stack($space);
            }
          }
        }
      }

      @include breakpoint($breakpoint) {
        .#{$name}#{$at}#{$breakpoint} {
          * + & {
            #{$prop}-top: stack(md);
          }
        }

        @each $space in map-keys($y-spaces) {
          .#{$name}--#{$space}#{$at}#{$breakpoint} {
            * + & {
              #{$prop}-top: stack($space);
            }
          }
        }
      }
    }

  }
}

@mixin make-pads($name:'u-pad', $prop:'padding') {
  $y-spaces: map-get($stack-map, y);

  @each $breakpoint in map-keys($breakpoints) {
    @if map-get($breakpoints, $breakpoint) == 0 {
      .#{$name} {
        #{$prop}-top: stack(md);
        #{$prop}-bottom: stack(md);
      }

      @each $space in map-keys($y-spaces) {
        .#{$name}--#{$space} {
          #{$prop}-top: stack($space);
          #{$prop}-bottom: stack($space);
        }
      }

    } @else {
      @include breakpoint(0, $breakpoint) {
        .#{$name}#{$at}#{$breakpoint}-down {
          #{$prop}-top: stack(md);
          #{$prop}-bottom: stack(md);
        }

        @each $space in map-keys($y-spaces) {
          .#{$name}--#{$space}#{$at}#{$breakpoint}-down {
            #{$prop}-top: stack($space);
            #{$prop}-bottom: stack($space);
          }
        }
      }

      @include breakpoint($breakpoint) {
        .#{$name}#{$at}#{$breakpoint} {
          #{$prop}-top: stack(md);
          #{$prop}-bottom: stack(md);
        }

        @each $space in map-keys($y-spaces) {
          .#{$name}--#{$space}#{$at}#{$breakpoint} {
            #{$prop}-top: stack($space);
            #{$prop}-bottom: stack($space);
          }
        }
      }
    }

  }
}

@include make-stacks();
@include make-pads();
