.entry-share-btns {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}
.entry-share-btns li {
  //display: inline-block;
  //margin: 2px 8px 8px 2px;
  //border-radius: 0px;
  background: none;
  margin-right: 1rem;
  //border: 1.5px solid #000;
  svg {
    fill: color(green);
  }
}
.entry-share-btns li:hover,
.entry-share-btns li:focus {
  background: none;
  svg {
    fill: color(green, dark);
  }
}
.entry-share-btns a,
.entry-content .entry-share-btns a {
  line-height: 25px;
  display: block;
  padding-left: 0;
  text-decoration: none;
  color: #000;
  border: none;
}
.entry-share-btns a:hover,
.entry-share-btns a:focus {
  text-decoration: none;
  color: #000;
}
.entry-share-btns b {
  font-weight: 400;
  display: none;
  //padding-left: 3px;
}
.entry-share-btns .count {
  display: inline-block;
  margin-left: 2px;
  //padding-left: 5px;
  border-left: 1px solid #555;
}
.entry-share-btns li:hover .count {
  border-left: 1px solid #444;
}
.entry-share-btns svg {
  display: inline-block;
  text-align: center;
  vertical-align: -3px;
}
@media (min-width: 600px) {
  .entry-share-btns b {
	display: inline-block;
  }
}