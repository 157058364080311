.s-copy {

  // Main heading
  > h1 {
    @include copy--h1;
    @include baseline(heading, h1, $type-heading, 3);
    font-weight: font-weight(heading, light);
    color: color(primary);
    & + * {
      @include trailer(heading, h1, $type-heading, 3, 2);
    }
  }

  > h2 {
    @include copy--h2;
    @include baseline(heading, h2, $type-heading);
    color: $heading-color;
    & + * {
      @include trailer(heading, h2, $type-heading);
    }
  }

  > h3 {
    @include copy--h3;
    @include baseline(heading, h3, $type-heading, 1.75);
    color: $heading-color;
    text-transform: uppercase;
    font-weight: font-weight(heading, semi-bold);
    & + * {
      @include trailer(heading, h3, $type-heading, 1, 0);
    }
  }

  > h4 {
    @include copy--h4;
    @include baseline(heading, h4, $type-heading, 2);

    & + * {
      @include trailer(heading, h4, $type-heading, 2, 0.5);
    }
  }

  a {
    font-weight: font-weight(heading, semi-bold);
    color: color(primary);
  }

  > p {
    @include copy--base;
    & + * {
      @include trailer(paragraph, body, $type-heading, 2, 2);
    }
  }

}
